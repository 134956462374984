import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Table } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useAppState } from '../../../context/AppContext'
import showNotification from '../../../helpers/showNotification'

function validateOrigin(origin: string): boolean {
  const pattern = /^https?:\/\/([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(\/.*)?$/

  if (!pattern.test(origin)) {
    return false
  }

  try {
    if (origin.includes('..')) {
      return false
    }

    const domainMatch = origin.match(/^https?:\/\/([^/]+)/)
    if (domainMatch) {
      const domain = domainMatch[1]

      const invalidChars = /[!@#$%^&*()_+=[\]{}|\\:;"'<>?,]/
      if (invalidChars.test(domain)) {
        return false
      }
    }

    return true
  } catch {
    return false
  }
}

export const BulkEditUrlDomainButton: FC<{
  selectedCampaignIds: number[]
  allCampaigns: { id: number; title: string }[]
}> = ({ selectedCampaignIds, allCampaigns }) => {
  const { apiClient } = useAppState()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setOpen(false)
  }
  const [form] = Form.useForm()
  const origin = Form.useWatch('origin', form)
  const [isFormValid, setIsFormValid] = useState(false)
  const validateForm = () =>
    form
      .validateFields()
      .then(() => {
        try {
          const u = new URL(origin)
          if ((!u.origin.startsWith('http://') && !u.origin.startsWith('https://')) || !u.origin) {
            throw new Error('Invalid origin. Must be in format https://example.com')
          }
          if (!validateOrigin(origin)) {
            throw new Error('Invalid origin. Must be in format https://example.com')
          }
        } catch (e) {
          showNotification({
            type: 'error',
            message: 'Invalid origin. Must be in format https://example.com',
          })
          setIsFormValid(false)
          return false
        }
        setIsFormValid(true)
        return true
      })
      .catch(() => {
        setIsFormValid(false)
        return false
      })

  useEffect(() => {
    setIsFormValid(true)
  }, [origin])

  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [open])

  const { mutateAsync: bulkEditUrloriginCampaigns } = apiClient.campaign.replaceDomain.useMutation()
  const handle = () => {
    validateForm().then(isValid => {
      if (!isValid) {
        return
      }
      setLoading(true)
      bulkEditUrloriginCampaigns({
        body: { ids: selectedCampaignIds, newOrigin: origin },
      })
        .then(() => {
          showNotification({
            type: 'success',
            message: 'Domains updated',
          })
        })
        .catch((err: any) => {
          if (err.status === 400 && err.body?.message === 'Request Validation Error') {
            showNotification({
              type: 'error',
              message: 'Invalid origin. Must be in format https://example.com',
            })
            return
          }
          console.error('error while bulk edit urls origins', err)
        })
        .finally(() => {
          form.resetFields()
          setLoading(false)
          setOpen(false)
        })
    })
  }
  return (
    <>
      <Button
        disabled={!selectedCampaignIds.length || loading}
        loading={loading}
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
      >
        Edit domains
      </Button>

      <Modal
        open={open}
        title="Edit domains"
        onOk={() => handle()}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={!selectedCampaignIds.length || loading || !isFormValid}
            onClick={() => handle()}
          >
            Edit domains
          </Button>,
        ]}
      >
        <Table
          rowKey="id"
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
          ]}
          dataSource={allCampaigns.filter(campaign => selectedCampaignIds.includes(campaign.id))}
          pagination={false}
          footer={() => (
            <Form form={form} key="form" initialValues={{ origin: '' }}>
              <Form.Item
                name="origin"
                rules={[
                  { required: true, message: 'Please input new origin' },
                  {
                    validator: (_rule, value) => {
                      if (!value) {
                        return Promise.resolve()
                      }
                      try {
                        if (!validateOrigin(value)) {
                          throw new Error('Invalid origin. Must be in format https://example.com')
                        }
                        return Promise.resolve()
                      } catch (e) {
                        return Promise.reject(new Error('Invalid origin. Must be in format https://example.com'))
                      }
                    },
                  },
                ]}
                tooltip="Origin includes protocol (http or https) and domain name: https://example.com"
                label="New origin"
                required
              >
                <Input placeholder="New origin" type="url" required />
              </Form.Item>
            </Form>
          )}
        />
      </Modal>
    </>
  )
}
