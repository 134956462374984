import { useMutation } from '@apollo/react-hooks'
import { gql } from 'graphql-tag'
import { CAMPAIGN_DATA } from '../Campaign/operations.js'

export const CREATE_OR_UPDATE_CAMPAIGN = gql`
  mutation CreateOrUpdateCampaign(
    $id: ID
    $title: String!
    $status: String
    $campaignType: String
    $bidType: String
    $url: String!
    $userId: Int
    # targets
    $subscriptionAgeEnabled: Boolean
    $subscriptionAgeFrom: Int
    $subscriptionAgeTo: Int
    $subscriptionAgeUnitsChoice: String
    # targets with include
    $lang: [String]
    $langInclude: Boolean
    $countryCode: [String]
    $countryCodeInclude: Boolean
    $region: [String]
    $regionInclude: Boolean
    $device: [Int]
    $deviceInclude: Boolean
    $os: [Int]
    $osInclude: Boolean
    $osVersion: [Int]
    $osVersionInclude: Boolean
    $browser: [Int]
    $browserInclude: Boolean
    $browserVersion: [Int]
    $browserVersionInclude: Boolean
    $browserVersionOp: CompareOperation
    $connectionType: [String]
    $connectionTypeInclude: Boolean
    $isp: [String]
    $ispInclude: Boolean
    $streams: [String]
    $streamsInclude: Boolean
    $substreams: [String]
    $substreamsInclude: Boolean
    $domains: [String]
    $domainsInclude: Boolean
    $useragents: [String]
    $useragentsInclude: Boolean
    # rest
    $maxClicked: Int
    $maxShown: Int
    $maxClickedUser: Int
    $maxShownUser: Int
    $maxShownUserAmtPeriod: Int
    $maxShownUserPeriod: Int
    $maxClickedUserAmtPeriod: Int
    $maxClickedUserPeriod: Int
    $price: Float!
    $targetCpa: Float
    $targetDaysAndHours: [DayAndHourInput]
    $scheduleTimezone: String
    $maxDailyBudget: Float
    $maxTotalBudget: Float
    $auditories: [ID]
    $auditoriesInclude: Boolean
    $userAuditoryConditions: [CampaignUserAuditoryConditionInput]
    $blacklists: [ID]
    $whitelists: [ID]
    $tags: [String]
  ) {
    createOrUpdateCampaign(
      id: $id
      title: $title
      status: $status

      url: $url

      userId: $userId

      campaignType: $campaignType
      bidType: $bidType

      subscriptionAgeEnabled: $subscriptionAgeEnabled
      subscriptionAgeFrom: $subscriptionAgeFrom
      subscriptionAgeTo: $subscriptionAgeTo
      subscriptionAgeUnitsChoice: $subscriptionAgeUnitsChoice

      lang: $lang
      langInclude: $langInclude
      countryCode: $countryCode
      countryCodeInclude: $countryCodeInclude
      region: $region
      regionInclude: $regionInclude
      device: $device
      deviceInclude: $deviceInclude
      os: $os
      osInclude: $osInclude
      osVersion: $osVersion
      osVersionInclude: $osVersionInclude
      browser: $browser
      browserInclude: $browserInclude
      browserVersion: $browserVersion
      browserVersionInclude: $browserVersionInclude
      browserVersionOp: $browserVersionOp
      connectionType: $connectionType
      connectionTypeInclude: $connectionTypeInclude
      isp: $isp
      ispInclude: $ispInclude
      streams: $streams
      streamsInclude: $streamsInclude
      substreams: $substreams
      substreamsInclude: $substreamsInclude
      domains: $domains
      domainsInclude: $domainsInclude
      useragents: $useragents
      useragentsInclude: $useragentsInclude

      maxClicked: $maxClicked
      maxShown: $maxShown
      maxClickedUser: $maxClickedUser
      maxShownUser: $maxShownUser
      maxShownUserAmtPeriod: $maxShownUserAmtPeriod
      maxShownUserPeriod: $maxShownUserPeriod
      maxClickedUserAmtPeriod: $maxClickedUserAmtPeriod
      maxClickedUserPeriod: $maxClickedUserPeriod
      price: $price
      targetCpa: $targetCpa
      targetDaysAndHours: $targetDaysAndHours
      scheduleTimezone: $scheduleTimezone
      maxDailyBudget: $maxDailyBudget
      maxTotalBudget: $maxTotalBudget
      auditories: $auditories
      auditoriesInclude: $auditoriesInclude
      userAuditoryConditions: $userAuditoryConditions

      blacklists: $blacklists
      whitelists: $whitelists
      tags: $tags
    ) {
      ...CampaignData
    }
  }
  ${CAMPAIGN_DATA}
`

export function useCreateOrUpdateCampaign(options = {}) {
  const [mutate, { data, error }] = useMutation(CREATE_OR_UPDATE_CAMPAIGN, options)
  return { mutate, data, error }
}
