import { parseIpAndCIDR } from '../../../helpers/parseIpAndCIDR'

export const getStringsFormatted = (strings = '') => {
  if (Array.isArray(strings)) return strings
  const splittedByComma = strings.split(',')
  const splittedByNewLine = []
  splittedByComma.flatMap(s => s.split('\n').filter(Boolean)).forEach(s => splittedByNewLine.push(s))
  const splittedBySpace = []
  splittedByNewLine.flatMap(s => s.split(' ').filter(Boolean)).forEach(s => splittedBySpace.push(s))

  return splittedBySpace
}

export const getSubstreamsFormatted = (substreams = '') => {
  return [...new Set(getStringsFormatted(substreams))]
}

export const buildCampaignFromFormValues = ({ formValues, initialValues }) => {
  const formattedValues = {}
  if (formValues.countryCode) {
    formattedValues.countryCode = formValues.countryCode.map(v => v.split(' ')[0])
  }
  if (formValues.region) {
    formattedValues.region = formValues.region
  }

  formattedValues.whitelists = formValues.whitelists ? formValues.whitelists.map(v => parseInt(v, 10)) : []
  formattedValues.blacklists = formValues.blacklists ? formValues.blacklists.map(v => parseInt(v, 10)) : []

  formattedValues.auditories = formValues.auditories ? formValues.auditories.map(v => parseInt(v, 10)) : []

  formattedValues.price = parseFloat(formValues.price.replace(',', '.'))
  if (formValues.targetCpa) {
    formattedValues.targetCpa = parseFloat(formValues.targetCpa.replace(',', '.'))
  } else {
    formattedValues.targetCpa = 0
  }

  formattedValues.ip = parseIpAndCIDR(formValues.ip)
  formattedValues.ip_Op = formValues.ip && formValues.ip.length ? formValues.ip_Op : null
  formattedValues.domains = (formValues.domains ? formValues.domains.split('\n') : [])
    .map(domain => domain.trim())
    .filter(Boolean)
  formattedValues.useragents = (formValues.useragents ? formValues.useragents.split('\n') : [])
    .map(useragent => useragent.trim())
    .filter(Boolean)
  formattedValues.substreams = Array.isArray(formValues.substreams)
    ? formValues.substreams
    : (formValues.substreams ? getSubstreamsFormatted(formValues.substreams) : [])
        .map(substream => substream.trim())
        .filter(Boolean)

  const userAuditoryConditions = []

  const maxClickedThreshold = Number(formValues.maxClickedThreshold) ?? 0
  if (Number.isFinite(formValues.maxClickedAuditory) && maxClickedThreshold > 0) {
    userAuditoryConditions.push({
      auditoryId: formValues.maxClickedAuditory,
      threshold: maxClickedThreshold,
      conditionType: 'CLICKS',
    })
  }

  const maxShownThreshold = Number(formValues.maxShownThreshold) ?? 0
  if (Number.isFinite(formValues.maxShownAuditory) && maxShownThreshold > 0) {
    userAuditoryConditions.push({
      auditoryId: formValues.maxShownAuditory,
      threshold: maxShownThreshold,
      conditionType: 'SHOWS',
    })
  }

  formattedValues.userAuditoryConditions = userAuditoryConditions

  if (formValues.subscriptionAgeEnabled && formValues.subscriptionAgeUnitsChoice === 'days') {
    const { subscriptionAgeFrom, subscriptionAgeTo } = fromSubDaysToSubHours(formValues)
    formattedValues.subscriptionAgeFrom = subscriptionAgeFrom
    formattedValues.subscriptionAgeTo = subscriptionAgeTo
  }

  return { ...initialValues, ...formValues, ...formattedValues }
}
export const getSubscriptionAgeParamsFromProps = ({
  subscriptionAgeFrom,
  subscriptionAgeTo,
  subscriptionAgeUnitsChoice,
}) => {
  if (subscriptionAgeUnitsChoice === 'hours') {
    return {
      subscriptionAgeFrom,
      subscriptionAgeTo,
    }
  }
  if (subscriptionAgeFrom === subscriptionAgeTo) {
    return {
      subscriptionAgeFrom: subscriptionAgeFrom / 24,
      subscriptionAgeTo: subscriptionAgeTo / 24,
    }
  }
  return {
    subscriptionAgeFrom: subscriptionAgeFrom / 24,
    subscriptionAgeTo: (subscriptionAgeTo + 1) / 24,
  }
}

/**
 * from 0 days to 0 days - from 0 hours to 0 hours
 * from 0 days to 1 days - from 0 hours to 23 hours
 * from 1 days to 1 days - from 24 hours to 24 hours
 * from 1 days to 2 days - from 24 hours to 47 hours
 * from 2 days to 2 days - from 48 hours to 48 hours
 * from 2 days to 3 days - from 48 hours to 71 hours
 * from 3 days to 3 days - from 72 hours to 72 hours
 * etc
 */
export const fromSubDaysToSubHours = ({ subscriptionAgeFrom, subscriptionAgeTo }) => {
  const from = subscriptionAgeFrom * 24
  const to = subscriptionAgeTo === 0 ? 0 : subscriptionAgeTo * 24 - 1
  if (subscriptionAgeFrom === 0 && subscriptionAgeTo === 0) {
    return { subscriptionAgeFrom: from, subscriptionAgeTo: to }
  }
  if (subscriptionAgeFrom === subscriptionAgeTo) {
    return { subscriptionAgeFrom: from, subscriptionAgeTo: to + 1 }
  }
  if (to === 0) {
    return { subscriptionAgeFrom: from, subscriptionAgeTo: to }
  }
  return { subscriptionAgeFrom: from, subscriptionAgeTo: to }
}

export const getSubscriptionAgeHoursString = ({
  subscriptionAgeFrom,
  subscriptionAgeTo,
  subscriptionAgeUnitsChoice,
}) => {
  if (subscriptionAgeUnitsChoice === 'hours') {
    return `${subscriptionAgeFrom} - ${subscriptionAgeTo}`
  }
  const { subscriptionAgeFrom: from, subscriptionAgeTo: to } = fromSubDaysToSubHours({
    subscriptionAgeFrom,
    subscriptionAgeTo,
  })
  return `${from} - ${to}`
}
