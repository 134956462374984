import { z } from 'zod';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const campaignTarget = c.router({
  getRegions: {
    method: 'GET',
    path: '/camapignTarget/getRegions',
    query: z.object({
      countries: z.string().array().optional(),
    }),
    responses: {
      200: z.record(z.string().array()),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
