import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { paginationRequest, paginationResponse } from './common/pagination';
import { AdmType } from './common/adm-types';
import { StreamTypeEnum } from './common/stream-type';

const c = initContract();

const streamType = z.enum(['PUSH', 'LINK', 'VIDEO_PRE_ROLL']);

export const stream = c.router({
  getStreamListPaginated: {
    method: 'GET',
    path: '/stream/getStreamListPaginated',
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            userId: z.number(),
            title: z.string(),
            streamType,
          })
          .array(),
        filterValues: z.object({
          ids: z.number().array(),
          userIds: z.number().array(),
        }),
        pagination: paginationResponse,
      }),
      500: z.object({
        message: z.string(),
      }),
    },
    query: z.object({
      filter: z
        .object({
          title: z.string().optional(),
          userIds: z.coerce.number().array().optional(),
          ids: z.coerce.number().array().optional(),
        })
        .optional(),
      pagination: paginationRequest,
    }),
  },

  deleteStream: {
    method: 'DELETE',
    path: '/stream/deleteStream',
    responses: {
      200: z.undefined(),
      500: z.object({
        message: z.string(),
      }),
    },
    body: z.object({
      id: z.coerce.number(),
    }),
  },

  createStream: {
    method: 'POST',
    path: '/stream/createStream',
    responses: {
      200: z.object({
        id: z.number(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
    body: z.object({
      userId: z.coerce.number().optional(),
      title: z.string(),
      region: z.enum(['eu', 'us']),
      billing: z.enum(['REDIRECT', 'NOTIFICATION', 'IMPRESSION']),
      bidModel: z.enum(['CPM', 'CPC']),
      format: z.enum(['JSON', 'XML', 'RTB', 'CUSTOM']),
      streamType,
      responseTemplate: z.string().default(''),
      batch: z.boolean().default(false),
      hasClientHints: z.boolean().default(false),
      timeout: z.number(),
      hidden: z.boolean().default(false),
      encrypted: z.boolean(),
      admType: z.nativeEnum(AdmType).optional(),
    }),
  },

  updateStream: {
    method: 'PATCH',
    path: '/stream/updateStream',
    responses: {
      200: z.object({
        id: z.number(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
    body: z.object({
      id: z.coerce.number(),
      userId: z.coerce.number().optional(),
      title: z.string().optional(),
      region: z.enum(['eu', 'us']),
      billing: z.enum(['REDIRECT', 'NOTIFICATION', 'IMPRESSION']),
      bidModel: z.enum(['CPM', 'CPC']),
      format: z.enum(['JSON', 'XML', 'RTB', 'CUSTOM']),
      streamType,
      responseTemplate: z.string().optional(),
      hasClientHints: z.boolean().optional(),
      timeout: z.number().optional(),
      batch: z.boolean().default(false),
      hidden: z.boolean().optional(),
      encrypted: z.boolean().optional(),
      admType: z.nativeEnum(AdmType).optional(),
    }),
  },

  getStream: {
    method: 'GET',
    path: '/stream/getStream',
    responses: {
      200: z.object({
        id: z.coerce.number(),
        title: z.string(),
        uid: z.string(),
        streamType,
        region: z.enum(['eu', 'us']),
        format: z.enum(['JSON', 'XML', 'RTB', 'CUSTOM']),
        billing: z.enum(['REDIRECT', 'NOTIFICATION', 'IMPRESSION']),
        bidModel: z.enum(['CPM', 'CPC']),
        hasClientHints: z.boolean(),
        timeout: z.number(),
        hidden: z.boolean(),
        encrypted: z.boolean(),
        admType: z.nativeEnum(AdmType).optional(),
        userId: z.coerce.number(),
        responseTemplate: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
    query: z.object({
      id: z.coerce.number(),
    }),
  },

  getStreamAutocomplete: {
    method: 'GET',
    path: '/stream/getStreamAutocomplete',
    query: z.object({
      campaignId: z.coerce.number().optional(),
      streamType: StreamTypeEnum.optional(),
    }),
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            title: z.string(),
            uid: z.string(),
            hasClientHints: z.boolean(),
            streamType,
          })
          .array(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
