import {
  BarChartOutlined,
  ControlOutlined,
  PauseOutlined,
  PictureOutlined,
  PlayCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'
// @ts-ignore
import { useChangeCampaignStatus } from '@pushflow/server/src/graphql/new/campaigns/changeCampaignStatus/operations'
// @ts-ignore
import { useDeleteCampaign } from '@pushflow/server/src/graphql/new/campaigns/deleteCampaign/operations'
// @ts-ignore
import { useCampaignQuery } from '@pushflow/server/src/graphql/new/campaigns/getCampaign/operations'
import { Col, Menu, Row, Tag } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PAUSED, WORKING } from '../../common/types/campaignStatusTypes'
import Button from '../../components/buttons/Button/Button'
import DeleteButton from '../../components/buttons/DeleteButton/DeleteButton'
import PageHeader from '../../components/layout/PageHeader/PageHeader'
import CampaignSettingsForm from '../../containers/campaign/CampaignSettingsForm'
import { LINK, PUSH, VIDEO_PRE_ROLL } from '../../common/types/campaignTypes'
import CampaignsStreamsReport from '../../containers/campaign/CampaignStreamsReport'
import CampaignStatsContainer from '../../containers/campaign/CampaignStatsContainer'

import { NotificationButton } from './components/notification-button'
import CampaignDebugView from './components/campaign-debug-view'
import { DEFAULT_TITLE } from '../../constant'
import { VideoCreativeList } from './components/video-creative-list'

import Creatives from './components/Creatives'

import { TrafficBlockersModal } from '../../containers/campaign/TrafficBlockersModal'

const SETTINGS_TAB = 'SETTINGS_TAB'
const STREAMS_TAB = 'STREAMS_TAB'
const CREATIVES_TAB = 'CREATIVES_TAB'

const SettingsTab: FC<{ data: { campaign: any }; refetch: () => void }> = ({ data, refetch }) => {
  return (
    <Row gutter={40} style={{ marginTop: 48 }}>
      <Col xs={{ span: 24 }} lg={{ span: 20 }}>
        <CampaignSettingsForm values={data.campaign} refetch={refetch} />
      </Col>
    </Row>
  )
}

const StreamsTab: FC<{ data: { campaign: any } }> = ({ data }) => {
  return (
    <Row gutter={40} style={{ marginTop: 48 }}>
      <Col>
        <CampaignsStreamsReport
          campaignId={data.campaign.id}
          campaignType={data.campaign.campaignType}
          campaignPrice={data.campaign.price}
        />
      </Col>
    </Row>
  )
}

const CreativesTab: FC<{ data: { campaign: any }; refetch: () => void }> = ({ data, refetch }) => {
  let tab

  if (data.campaign.campaignType === 'VIDEO_PRE_ROLL') {
    tab = <VideoCreativeList campaign={data.campaign} refetchCamapignData={refetch} />
  }
  if (data.campaign.campaignType === 'PUSH') {
    tab = <Creatives campaign={data.campaign} refetchCamapignData={refetch} />
  }

  return (
    <Row gutter={40} style={{ marginTop: 48 }}>
      <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ minWidth: '260px' }}>
        {tab}
      </Col>
    </Row>
  )
}

const components = {
  SETTINGS_TAB: SettingsTab,
  STREAMS_TAB: StreamsTab,
  CREATIVES_TAB: CreativesTab,
}

const getTabOptions = (campaignType: string) => {
  if (campaignType === PUSH) {
    return { tagColor: 'purple', tagTitle: 'PUSH' }
  } else if (campaignType === LINK) {
    return { tagColor: 'blue', tagTitle: 'LINK' }
  } else if (campaignType === VIDEO_PRE_ROLL) {
    return { tagColor: 'green', tagTitle: 'VIDEO PRE ROLL' }
  }
  return { tagColor: 'red', tagTitle: 'UNKNOWN' }
}

const CampaignSettingsPage: FC<{ params: { id: number } }> = ({ params }) => {
  const { loading, error, data, refetch } = useCampaignQuery(params.id)
  const { mutate: changeCampaignStatus } = useChangeCampaignStatus()
  const { mutate: deleteCampagin } = useDeleteCampaign()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (data && data.campaign && data.campaign.title) {
      document.title = `${data.campaign.title}`
    }

    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [data])

  const [currentTab, setTab] = useState<keyof typeof components>(SETTINGS_TAB)
  const handleTabClick = (e: any) => setTab(e.key)

  if (loading && !data) return <div>{t('Common.Loading')}</div>
  if (error)
    return (
      <>
        `${t('Common.Error')}!: ${error}`
      </>
    )

  const { title, id, status, campaignType } = data.campaign

  const TabComponent = components[currentTab]

  const tabOptions = getTabOptions(campaignType)

  return (
    <>
      <PageHeader
        breadcrumbs={[{ title: t('Campaign.Campaigns'), link: '/campaign' }, { title: `ID: ${id}` }]}
        title={title}
        tags={
          <div>
            {status === WORKING ? (
              <Tag color="success">{t('Campaign.Status.Running')}</Tag>
            ) : (
              <Tag color="warning">{t('Campaign.Status.Stopped')}</Tag>
            )}
            <Tag color={tabOptions.tagColor}>{tabOptions.tagTitle}</Tag>
          </div>
        }
        extra={[
          <NotificationButton
            key="notification-btn"
            notificationEnabled={data.campaign.notificationEnabled}
            campaignId={data.campaign.id}
          />,
          <ChangeStatusButton
            key="change-status-btn"
            status={status}
            changeCampaignStatus={changeCampaignStatus}
            id={id}
          />,

          <DeleteButton
            key="delete-btn"
            isOnlyIcon
            onClick={() =>
              deleteCampagin({
                variables: { id },
              }).then(() => navigate('/'))
            }
          />,
          <Button
            key="campaign-rules"
            icon={<ControlOutlined />}
            onClick={() => navigate(`/rules/${data.campaign.id}`)}
          />,
          <TrafficBlockersModal campaignId={id} key="traffic-blockers" />,
          <CampaignDebugView key="debug-view" campaignId={data.campaign.id} />,
        ]}
      ></PageHeader>

      <CampaignStatsContainer campaignId={id} />

      <Menu
        onClick={handleTabClick}
        selectedKeys={[currentTab]}
        mode="horizontal"
        style={{ marginBottom: 24, marginTop: 24 }}
      >
        <Menu.Item key={SETTINGS_TAB} icon={<SettingOutlined />} style={{ fontSize: 18 }}>
          {t('Campaign.Settings')}
        </Menu.Item>
        {campaignType === PUSH || campaignType === VIDEO_PRE_ROLL ? (
          <Menu.Item key={CREATIVES_TAB} icon={<PictureOutlined />} style={{ fontSize: 18 }}>
            {t('Campaign.Creatives')}
          </Menu.Item>
        ) : (
          <></>
        )}
        <Menu.Item key={STREAMS_TAB} icon={<BarChartOutlined />} style={{ fontSize: 18 }}>
          {t('Campaign.Streams')}
        </Menu.Item>
      </Menu>
      {<TabComponent data={data} refetch={refetch} />}
    </>
  )
}

const ChangeStatusButton: FC<{
  status: typeof WORKING | typeof PAUSED
  id: number
  changeCampaignStatus: (...p: any[]) => any
}> = ({ status, id, changeCampaignStatus }) => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={() =>
        changeCampaignStatus({
          variables: {
            id,
            status: status === WORKING ? PAUSED : WORKING,
          },
        })
      }
      type={status === WORKING ? `default` : 'primary'}
      icon={status === WORKING ? <PauseOutlined /> : <PlayCircleOutlined />}
    >
      {status === WORKING ? t('CampaignPage.Stop scheduled sending') : t('CampaignPage.Run on schedule')}
    </Button>
  )
}

export default CampaignSettingsPage
