import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { ErrorCode } from './common/error.enum';

const c = initContract();

export const streamSubstreamSettings = c.router({
  resetAllStreamSubstreamSettings: {
    method: 'POST',
    path: '/streamSubstreamSettings/resetAllStreamSubstreamSettings',
    body: z.object({
      campaignId: z.coerce.number(),
      settingsType: z.enum(['STREAM', 'SUBSTREAM']),
    }),
    responses: {
      200: z.object({
        ok: z.boolean(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.CAMPAIGN_NOT_FOUND]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
