import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
import * as Sentry from '@sentry/react'
import { Avatar, Card, Dropdown, Form, Modal, Select, Space, Tooltip } from 'antd'
import pick from 'lodash/pick'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PAUSED, WORKING } from '../../../common/types/campaignStatusTypes'
import Button from '../../../components/buttons/Button/Button'
import showNotification from '../../../helpers/showNotification'
import CreativeForm from '../../../containers/campaign/CreativeForm'
import { GenerateCreativesForm } from '../../../containers/campaign/GenerateCreativesForm'
import { useAppState } from '../../../context/AppContext'
import React from 'react'
import { ICreative } from '../../creative/creative-list.page'
import { CreativeModerationIndicator } from './creative-moderation-indicator'
import PopConfirmButton from '../../../components/buttons/PopConfirmButton/PopConfirmButton'

const useCreativeClipboard = () => {
  const getCopiedCreativeFromLocalstorage = () => {
    const copiedCreative = localStorage.getItem('copiedCreative')
    if (copiedCreative) {
      return JSON.parse(copiedCreative)
    }
    return null
  }
  const setCopiedCreativeToLocalStorage = (creative: any) => {
    localStorage.setItem('copiedCreative', JSON.stringify(creative))
  }
  const [copiedCreative, setCopiedCreative] = useState(getCopiedCreativeFromLocalstorage())
  const copyCreative = (creative: any) => {
    const newCopiedCreative = {
      ...pick(creative, ['body', 'title', 'tags']),
      sourceCreativeId: creative.id,
    }
    setCopiedCreative(newCopiedCreative)
    setCopiedCreativeToLocalStorage(newCopiedCreative)
  }
  return {
    copiedCreative,
    copyCreative,
  }
}

const { Meta } = Card

const AddExistingCreativeModal: FC<{
  campaignId: number
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  setDefaultOpenedCreativeId: (id: number) => void
}> = ({ campaignId, isOpen, onClose, refetch, setDefaultOpenedCreativeId }) => {
  const { apiClient } = useAppState()
  const { t } = useTranslation()
  const { mutateAsync: attachCreative } = apiClient.creative.addExistingCreative.useMutation()

  const { data } = apiClient.creative.getCreativeListPaginated.useQuery(['', 0, 1000, [].join(), [].join()], {
    query: {
      pagination: {
        pageNumber: 0,
        pageSize: 1000,
      },
    },
  })

  const [form] = Form.useForm()
  const url = Form.useWatch('url', form)
  const creativeId = Form.useWatch('creativeId', form)
  const [loading, setLoading] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const validateForm = () =>
    form
      .validateFields()
      .then(() => {
        setIsFormValid(true)
        return true
      })
      .catch(() => {
        setIsFormValid(false)
        return false
      })
  useEffect(() => {
    setIsFormValid(true)
  }, [url])
  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [isOpen])

  const onSubmit = async () => {
    validateForm().then(async isValid => {
      if (!isValid) {
        return
      }
      setLoading(true)
      try {
        const { body } = await attachCreative({
          body: {
            creativeId: +creativeId,
            campaignId: +campaignId,
          },
        })
        const newCreativeId = body.creativeId
        setDefaultOpenedCreativeId(newCreativeId)
        await refetch()
        showNotification({
          type: 'success',
          message: `${t('Creative.Add Existing Success')}`,
        })
        onClose()
      } catch (err: any) {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') {
          Sentry.captureException(err)
        }
      } finally {
        setLoading(false)
      }
    })
  }

  return (
    <Modal
      title={t('Creative.Add Existing Title')}
      open={isOpen}
      onOk={onSubmit}
      onCancel={() => onClose()}
      footer={[
        <Button key="back" onClick={() => onClose()} disabled={loading}>
          {t('Creative.Add Existing Cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={loading || !isFormValid}
          onClick={() => onSubmit()}
        >
          {t('Creative.Add Existing Confirm')}
        </Button>,
      ]}
    >
      <Form form={form} key="form" layout="vertical" initialValues={{ campaignId: '' }}>
        <Form.Item name="creativeId" label={t('Creative.Add Existing Select Creative')} rules={[{ required: true }]}>
          <Select
            style={{ width: '100%' }}
            // @ts-ignore
            options={data?.body.items.map((creative: ICreative) => {
              const body = creative.body
              const title = creative.title
              return {
                value: creative.id,
                label: `${title}${body ? ` | ${body}` : ''}`,
              }
            })}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const AddCreativeButton: FC<{
  campaignId: number
  onClick: () => void
  refetch: () => void
  setDefaultOpenedCreativeId: (id: number) => void
}> = ({ campaignId, onClick, refetch, setDefaultOpenedCreativeId }) => {
  const { t } = useTranslation()

  const [isAddExistingModalOpen, setAddExistingModalOpen] = useState(false)

  return (
    <>
      <AddExistingCreativeModal
        isOpen={isAddExistingModalOpen}
        onClose={() => setAddExistingModalOpen(false)}
        campaignId={campaignId}
        refetch={refetch}
        setDefaultOpenedCreativeId={setDefaultOpenedCreativeId}
      />

      <Dropdown.Button
        type="primary"
        onClick={onClick}
        menu={{
          items: [{ label: 'Add existing creative', key: '1' }],
          onClick: el => {
            if (el.key === '1') {
              setAddExistingModalOpen(true)
            }
          },
        }}
      >
        {t('Actions.Add new')}
      </Dropdown.Button>
    </>
  )
}

export const PasteCreativeButton: FC<{
  campaignId: number
  refetch: () => void
  copiedCreative: { title: string; sourceCreativeId: number }
}> = ({ refetch, copiedCreative, campaignId }) => {
  const { apiClient } = useAppState()
  const { t } = useTranslation()
  const [pasting, setPasting] = useState(false)
  const { mutateAsync: createCreative } = apiClient.creative.addExistingCreative.useMutation({
    onSuccess: () => {
      refetch()
    },
  })
  const paste = () => {
    if (!copiedCreative) {
      return
    }
    setPasting(true)
    createCreative({ body: { creativeId: copiedCreative.sourceCreativeId, campaignId } })
      .then(() => {
        showNotification({ type: 'success', message: t('Messages.Pasted') })
        refetch()
      })
      .catch((err: any) => {
        showNotification({
          type: 'error',
          message: `${t('Common.Error')}: ${err.message ? err.message : 'Can not create creative'}`,
        })
        if (process.env.NODE_ENV !== 'development') Sentry.captureException(err)
      })
      .finally(() => {
        setPasting(false)
      })
  }

  return (
    <Tooltip placement="top" title={copiedCreative.title}>
      <Button loading={pasting} disabled={pasting} onClick={() => paste()}>
        {t('Actions.Paste')}
      </Button>
    </Tooltip>
  )
}

const Creatives: FC<{
  campaign: { id: number }
  refetchCamapignData: () => void
}> = ({ campaign, refetchCamapignData }) => {
  const campaignId = campaign.id
  const { apiClient, user } = useAppState()
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const {
    isLoading: loading,
    error,
    data,
    refetch,
  } = apiClient.creative.getCreativeListPaginated.useQuery(['', 0, 100, [campaignId].join(), [].join()], {
    query: {
      filter: {
        campaignIds: [campaignId],
      },
      pagination: {
        pageNumber: 0,
        pageSize: 100,
      },
    },
  })

  const { copiedCreative, copyCreative } = useCreativeClipboard()

  const [defaultOpenedCreativeId, setDefaultOpenedCreativeId] = useState<number | null>(null)
  const [showGenerateCreativesForm, setShowGenerateCreativesForm] = useState(false)

  const { mutateAsync: bulkDeleteCreatives } = apiClient.campaign.bulkDeleteCampaignCreatives.useMutation({
    onSuccess: () => {
      showNotification({ type: 'success', message: 'Creatives deleted' })
      refetchCamapignData()
      refetch()
    },
    onError: (err: any) => {
      console.error('Error during bulk delete creatives:', err)
      showNotification({
        type: 'error',
        message: `${t('Common.Error')}: ${err.message ? err.message : 'Can not delete creatives'}`,
      })
    },
  })
  const { mutateAsync: bulkPauseCreatives } = apiClient.campaign.bulkPauseCampaignCreatives.useMutation({
    onSuccess: () => {
      showNotification({ type: 'success', message: 'Creatives paused' })
      refetchCamapignData()
      refetch()
    },
    onError: (err: any) => {
      console.error('Error during bulk pause creatives:', err)
      showNotification({
        type: 'error',
        message: `${t('Common.Error')}: ${err.message ? err.message : 'Can not pause creatives'}`,
      })
    },
  })

  const creatives = data?.body.items ? data.body.items : []
  useEffect(() => {
    const activeCreatives = data?.body.items.filter((creative: any) => creative.status === WORKING)
    if (data && activeCreatives && activeCreatives.length === 0) {
      showNotification({ type: 'info', message: 'There is no creatives in campaign. Campaign stopped' })
    }
  }, [creatives])

  if (loading && !data) return <div>{t('Common.Loading')}</div>

  if (error)
    return (
      <>
        `${t('Common.Error')}: ${error}`
      </>
    )

  return (
    <div className="Creatives">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '40px',
          flexWrap: 'wrap',
        }}
      >
        {creatives.map((creative: any) => (
          <Creative
            key={creative.id}
            refetch={refetch}
            refetchCamapignData={refetchCamapignData}
            campaignId={campaignId}
            creative={creative}
            copyCreative={copyCreative}
            defaultOpenedCreativeId={defaultOpenedCreativeId}
            setDefaultOpenedCreativeId={setDefaultOpenedCreativeId}
          />
        ))}
      </div>

      <Space>
        <AddCreativeButton
          campaignId={campaignId}
          onClick={() => setVisible(true)}
          refetch={refetch}
          setDefaultOpenedCreativeId={setDefaultOpenedCreativeId}
        />
        {!!copiedCreative && (
          <PasteCreativeButton refetch={refetch} copiedCreative={copiedCreative} campaignId={campaignId} />
        )}
        {user && (user.roles.includes('ADMIN') || user.roles.includes('INTERNAL_ADVERTISER')) && (
          <Button
            onClick={() => {
              setShowGenerateCreativesForm(!showGenerateCreativesForm)
            }}
          >
            Generate Creatives
          </Button>
        )}
        <PopConfirmButton
          isOnlyIcon
          prompt="You are about to delete all campaign creatives. This also will stop your campaign"
          okText={'Delete all creatives and stop campaign'}
          onClick={() => {
            bulkDeleteCreatives({ body: { campaignId } })
          }}
          placement={'top'}
          icon={<DeleteOutlined />}
        />
        <PopConfirmButton
          isOnlyIcon
          prompt="You are about to pause all campaign creatives. This also will stop your campaign"
          okText={'Pause all creatives and stop campaign'}
          onClick={() => {
            bulkPauseCreatives({ body: { campaignId } })
          }}
          placement={'top'}
          icon={<PauseCircleOutlined />}
        />
      </Space>

      <CreativeForm
        refetch={refetch}
        refetchCampaignData={refetchCamapignData}
        campaignId={campaignId}
        visible={visible}
        setVisible={setVisible}
      />
      <GenerateCreativesForm
        refetch={refetch}
        campaignId={campaignId}
        visible={showGenerateCreativesForm}
        setVisible={setShowGenerateCreativesForm}
      />
    </div>
  )
}

const Creative: FC<{
  creative: ICreative
  refetch: () => void
  refetchCamapignData: () => void
  campaignId: number
  copyCreative: (creative: any) => void
  defaultOpenedCreativeId: number | null
  setDefaultOpenedCreativeId: (id: number | null) => void
}> = ({
  creative,
  refetch,
  refetchCamapignData,
  campaignId,
  copyCreative,
  defaultOpenedCreativeId,
  setDefaultOpenedCreativeId,
}) => {
  const {
    id,
    status,
    title: displayTitle,
    body: displayBody,
    previewImgUrl: displayPreviewImageUrl,
    bodyImgUrl: displayBodyImageUrl,
    moderationStatus,
  } = creative

  const { apiClient } = useAppState()

  const { mutateAsync: deleteCreative } = apiClient.creative.deleteCreative.useMutation()
  const { mutateAsync: changeCreativeStatus } = apiClient.creative.changeCreativeStatus.useMutation()
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (defaultOpenedCreativeId === +id) {
      setDefaultOpenedCreativeId(null)
      setVisible(true)
    }
  }, [defaultOpenedCreativeId])
  function openModal() {
    setVisible(true)
  }

  function remove() {
    deleteCreative({
      body: { id },
    }).then(() => {
      showNotification({ type: 'success', message: 'Creatives deleted' })
      refetchCamapignData()
      return refetch && refetch()
    })
  }

  const copy = () => {
    copyCreative(creative)
  }

  function updateStatus() {
    changeCreativeStatus({
      body: {
        id,
        status: status === PAUSED ? WORKING : PAUSED,
      },
    })
      .then(() => {
        showNotification({
          type: 'success',
          message: status === PAUSED ? WORKING : PAUSED,
        })
        refetch && refetch()
        refetchCamapignData && refetchCamapignData()
      })
      .catch(err => {
        console.error('error while running creative', err)
      })
  }
  const actionButtons = [
    <div style={{ fontSize: 13, color: '#8e8e8e' }} key="1">
      ID: {id}
    </div>,

    <CreativeModerationIndicator moderationStatus={moderationStatus} />,
    status === PAUSED ? (
      <Tooltip title={t('Creative.Help.Start tooltip')} placement="bottom">
        <PlayCircleOutlined style={{ color: 'red' }} key="1" onClick={updateStatus} />
      </Tooltip>
    ) : (
      <Tooltip title={t('Creative.Help.Pause tooltip')} placement="bottom">
        <PauseCircleOutlined style={{ color: 'green' }} key="2" onClick={updateStatus} />
      </Tooltip>
    ),
    <EditOutlined key="3" onClick={openModal} />,
    <Dropdown
      key="6"
      menu={{
        items: [
          { label: t('Actions.Delete'), key: 'delete', onClick: () => remove() },
          { label: t('Actions.Copy'), key: 'copy', onClick: () => copy() },
        ],
      }}
      trigger={['click']}
    >
      <EllipsisOutlined />
    </Dropdown>,
  ]
  return (
    <>
      <Card
        style={{ minWidth: '250px', marginBottom: 24 }}
        cover={
          displayBodyImageUrl && (
            <div
              style={{
                backgroundPosition: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                margin: '0 auto',
                paddingTop: '67%',
                backgroundImage: `url(${displayBodyImageUrl})`,
              }}
            >
              {' '}
            </div>
          )
        }
        actions={actionButtons}
      >
        <Meta avatar={<Avatar shape="square" src={displayPreviewImageUrl} />} title={displayTitle} />
        <p style={{ overflowWrap: 'break-word', margin: '1rem 0' }}>{displayBody}</p>
      </Card>
      <CreativeForm
        refetch={refetch}
        refetchCampaignData={refetchCamapignData}
        campaignId={campaignId}
        visible={visible}
        setVisible={setVisible}
        creative={creative}
      />
    </>
  )
}

export default Creatives
