import { z } from 'zod';
import { initContract } from '@ts-rest/core';

const c = initContract();

export type BlockedByFeilds =
  | 'totalRequests'
  | 'blocked_by_streams'
  | 'blocked_by_substreams'
  | 'blocked_by_country'
  | 'blocked_by_lang'
  | 'blocked_by_device'
  | 'blocked_by_os'
  | 'blocked_by_browser'
  | 'blocked_by_osVersion'
  | 'blocked_by_browserVersion'
  | 'blocked_by_connectionType'
  | 'blocked_by_domain'
  | 'blocked_by_isp'
  | 'blocked_by_subage';

export type TotalByFeilds =
  | 'totalRequests'
  | 'total_by_streams'
  | 'total_by_substreams'
  | 'total_by_country'
  | 'total_by_lang'
  | 'total_by_device'
  | 'total_by_os'
  | 'total_by_browser'
  | 'total_by_osVersion'
  | 'total_by_browserVersion'
  | 'total_by_connectionType'
  | 'total_by_domain'
  | 'total_by_isp'
  | 'total_by_subage';

export interface BlockersData extends Record<BlockedByFeilds, number> {}
export interface TotalData extends Record<TotalByFeilds, number> {}

export interface TrafficBlockersCampaignData extends BlockersData {
  campaign_id: number;
  date: string;
}

export interface TrafficBlockersStreamData extends BlockersData {
  campaign_id: number;
  stream: string;
  date: string;
}

export interface TrafficBlockersStreamSubstreamData extends BlockersData {
  campaign_id: number;
  stream: string;
  substream_id: string;
  date: string;
}

export type TrafficBlockersData =
  | Partial<TrafficBlockersCampaignData>
  | Partial<TrafficBlockersStreamData>
  | Partial<TrafficBlockersStreamSubstreamData>;

export const TRAFFIC_BLOCKERS_INTERVALS = {
  day: 'day' as const,
  week: 'week' as const,
  month: 'month' as const,
} as const;

export type TrafficBlockersInterval =
  (typeof TRAFFIC_BLOCKERS_INTERVALS)[keyof typeof TRAFFIC_BLOCKERS_INTERVALS];

export const trafficBlockers = c.router({
  getTrafficBlockers: {
    method: 'GET',
    path: '/campaign/trafficBlockers',
    query: z.object({
      campaignId: z.coerce.number(),
      stream: z.string().optional(),
      substream: z.string().optional(),
      interval: z.enum(['day', 'week', 'month']).optional().default('week'),
    }),
    responses: {
      200: z.object({
        blocked: z.record(z.string(), z.coerce.number()),
        total: z.record(z.string(), z.coerce.number()),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
