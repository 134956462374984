import { ClearOutlined, CloudDownloadOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons'
// @ts-ignore
import { useReportCampaignStreamsQuery } from '@pushflow/server/src/graphql/new/campaigns/campaignStreamsReport/operations'
// @ts-ignore
import { useChangeCampaignStreamStatus } from '@pushflow/server/src/graphql/new/campaignStreamSubstream/changeCampaignStreamStatus/operations'
// @ts-ignore
import { useChangeCampaignSubstreamStatus } from '@pushflow/server/src/graphql/new/campaignStreamSubstream/changeCampaignSubstreamStatus/operations'
// @ts-ignore
import { useChangeCampaignStreamBid } from '@pushflow/server/src/graphql/new/campaignStreamSubstream/changeCampaignStreamBid/operations'
// @ts-ignore
import { useChangeCampaignSubstreamBid } from '@pushflow/server/src/graphql/new/campaignStreamSubstream/changeCampaignSubstreamBid/operations'
// @ts-ignore
import { useResetSubstreamSettings } from '@pushflow/server/src/graphql/new/campaignStreamSubstream/resetSubstreamSettings/operations'
// @ts-ignore
import { useResetStreamSettings } from '@pushflow/server/src/graphql/new/campaignStreamSubstream/resetStreamSettings/operations'
import { Button, Col, DatePicker, Form, InputNumber, Row, Select, Space, Switch, Table, Tag, Tooltip } from 'antd'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
// @ts-ignore
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useAppState } from '../../context/AppContext'
import { createLabel } from '../../helpers/createAutocompleteLabel'
import TimezoneSelect from '../../components/forms/TimezoneSelect/TimezoneSelect'
import { Link } from 'react-router-dom'
import { PAUSED, WORKING } from '../../common/types/campaignStatusTypes'
import showNotification from '../../helpers/showNotification'
import './style.scss'
import { CampaignType, StreamType, UserRole } from '@pushflow/backend-http-contract'
import PopConfirmButton from '../../components/buttons/PopConfirmButton/PopConfirmButton'

const { Option } = Select
const { Column } = Table
const { RangePicker } = DatePicker

const defaultState: any = {
  CampaignStreamsParams: {
    groupBy: 'SUBSTREAM_ID',
    filterByStreamId: [],
    timezone: '+0',
    dateRange: [moment().subtract(14, 'd'), moment().endOf('day')],
    dateFrom: moment().subtract(14, 'd').format('YYYY-MM-DD'),
    dateTo: moment().add('1d').format('YYYY-MM-DD'),
  },
  filteredInfo: {},
  sortedInfo: {},
  pagination: {
    pageSizeOptions: [10, 20, 50, 100, 400],
    showSizeChanger: true,
    pageSize: 50,
  },
}

const CampaignsStreamsCampaign: FC<{
  campaignId: number
  campaignType: CampaignType
  campaignPrice: number
}> = props => {
  const { campaignId, campaignPrice, campaignType } = props
  const { user, apiClient } = useAppState()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { data: streamsAutocomplete } = apiClient.stream.getStreamAutocomplete.useQuery(['streamsAutocomplete'], {
    query: {
      campaignId,
      streamType: campaignType,
    },
  })
  const groupBy = Form.useWatch('groupBy', form)

  const { mutate: changeCampaignStreamStatus } = useChangeCampaignStreamStatus()
  const { mutate: changeCampaignSubstreamStatus } = useChangeCampaignSubstreamStatus()
  const { mutate: changeCampaignStreamBid } = useChangeCampaignStreamBid()
  const { mutate: changeCampaignSubstreamBid } = useChangeCampaignSubstreamBid()
  const { mutate: resetSubstreamSettings } = useResetSubstreamSettings()
  const { mutate: resetStreamSettings } = useResetStreamSettings()

  const { mutateAsync: resetAllSettings } =
    apiClient.streamSubstreamSettings.resetAllStreamSubstreamSettings.useMutation()

  const [reloadCampaignStreams, setReloadCampaignStreams] = useState(false)
  const [streams, setStreams] = useState<
    Array<{ id: number; title: string; uid: string; hasClientHints: boolean; streamType: StreamType }>
  >([])
  const [CampaignData, setCampaignData] = useState<Array<any>>([])
  const [CampaignStreamsCSVData, setCampaignStreamsCSVData] = useState([])
  const [CampaignStreamsParams, setCampaignStreamsParams] = useState(defaultState.CampaignStreamsParams)
  const { refetch, data } = useReportCampaignStreamsQuery({
    campaignId: Number(campaignId),
    ...CampaignStreamsParams,
    groupBy: groupBy,
  })
  const [pagination, setPagination] = useState(defaultState.pagination)
  const [filteredInfo, setFilteredInfo] = useState(defaultState.filteredInfo)
  const [sortedInfo, setSortedInfo] = useState(defaultState.sortedInfo)
  const [cache, setCache] = useState<any>({})

  const [isChangingRow, setIsChangingRow] = useState(false)
  const [changingId, setChangingId] = useState<string | null>(null)

  const timezone = Form.useWatch('timezone', form)

  useEffect(() => {
    if (reloadCampaignStreams) {
      refetch(CampaignStreamsParams).then(({ data }: any) => {
        setReloadCampaignStreams(false)
        setCampaignData(data.reportCampaignStreams)
      })
    }
    if (data && data.reportCampaignStreams) {
      setCampaignData(data.reportCampaignStreams)
      setCampaignStreamsCSVData(
        data.reportCampaignStreams.map((obj: any) => {
          const item = { ...obj }

          delete item.__typename

          if (item.stream) {
            item.streamTitle = item.stream.title
            item.streamId = item.stream.id
          }

          delete item.stream

          return item
        })
      )
    }

    if (streamsAutocomplete?.body) {
      const { items } = streamsAutocomplete?.body

      const streamsList = items.map(stream => {
        return {
          ...stream,
          value: stream.id,
          key: 'stream-' + stream.id,
          label: createLabel(user, stream),
        }
      })

      setStreams(streamsList)

      setCache({ streams: streamsList })
    }
  }, [streamsAutocomplete?.body, reloadCampaignStreams, data])

  const onFinish = (event: any) => {
    event.preventDefault()

    const values = form.getFieldsValue()

    setCampaignStreamsParams({
      ...CampaignStreamsParams,
      ...values,
      dateFrom: values.dateRange ? values.dateRange[0].format('YYYY-MM-DD') : null,
      dateTo: values.dateRange ? values.dateRange[1].format('YYYY-MM-DD') : null,
      filterByStreamId: values.filterByStreamId ? values.filterByStreamId.map(Number) : null,
    })

    setReloadCampaignStreams(true)
  }

  const handleChangeStreamId = (value: Array<string>) => {
    if (value.length) {
      const streams = cache.streams.filter((e: { id: number }) => value.includes(`${e.id}`))

      setStreams(streams)
    } else {
      setStreams(cache.streams)
    }

    setCampaignStreamsParams({
      ...CampaignStreamsParams,
      filterByStreamId: [...CampaignStreamsParams.filterByStreamId, Number(value)],
    })
  }

  const handleGroupByChange = (value: any) => {
    setCampaignStreamsParams({ ...CampaignStreamsParams, groupBy: value })
    setReloadCampaignStreams(true)
  }

  const clearAll = () => {
    form.resetFields()
    form.setFieldsValue(defaultState.CampaignStreamsParams)
    setCampaignStreamsParams(defaultState.CampaignStreamsParams)
    setPagination(defaultState.pagination)
    setSortedInfo(defaultState.sortedInfo)
    setFilteredInfo(defaultState.filteredInfo)
    setCampaignStreamsParams(defaultState.CampaignStreamsParams)
    setStreams(cache.sterams)
    setReloadCampaignStreams(true)
  }

  const handleSettingFilters = (pagination: any, filteredInfo: any, sortedInfo: any) => {
    setPagination(pagination)
    setSortedInfo(sortedInfo)
    setFilteredInfo(filteredInfo)
  }

  const streamSorter = (a: { campaign: { title: string } }, b: { campaign: { title: string } }) => {
    const aTitle = a.campaign && a.campaign.title ? a.campaign.title : ''
    const bTitle = b.campaign && b.campaign.title ? b.campaign.title : ''
    return aTitle.localeCompare(bTitle, 'en', { numeric: true })
  }

  const handleChangeStatus = (record: { group: string; streamUid: string }, status: string) => {
    setIsChangingRow(true)
    setChangingId(record.group)
    const name = groupBy === 'STREAM_ID' ? 'Stream' : 'Substream'
    const fn = groupBy === 'STREAM_ID' ? changeCampaignStreamStatus : changeCampaignSubstreamStatus
    const variables: any = { campaignId: Number(campaignId), status }
    if (groupBy === 'STREAM_ID') {
      variables.streamUid = record.group
    }
    if (groupBy === 'SUBSTREAM_ID') {
      variables.streamUid = record.streamUid
      variables.substream = record.group
    }
    fn({ variables })
      .then((v: any) => {
        if (!v.errors) {
          showNotification({ type: 'success', message: `${name} status changed` })
          return
        } else {
          showNotification({
            type: 'error',
            message: 'Error while changing status: ' + v.errors[0].message,
          })
        }
      })
      .catch((e: any) => {
        showNotification({ type: 'error', message: 'Error: ' + e.message })

        if (process.env.NODE_ENV !== 'development') Sentry.captureException(e)
      })
      .finally(() => {
        setIsChangingRow(false)
        setChangingId(null)
        setReloadCampaignStreams(true)
      })
  }

  const handleChangeBidFocusLeave = (record: { streamUid: string; group: string }, bid: number) => {
    setIsChangingRow(true)
    setChangingId(record.group)
    const name = groupBy === 'STREAM_ID' ? 'Stream' : 'Substream'
    const fn = groupBy === 'STREAM_ID' ? changeCampaignStreamBid : changeCampaignSubstreamBid
    const variables: any = { campaignId: Number(campaignId), bid }
    if (groupBy === 'STREAM_ID') {
      variables.streamUid = record.group
    }
    if (groupBy === 'SUBSTREAM_ID') {
      variables.streamUid = record.streamUid
      variables.substream = record.group
    }
    fn({ variables })
      .then((v: any) => {
        if (!v.errors) {
          showNotification({ type: 'success', message: `${name} bid changed to ${bid}$` })
          return
        } else {
          showNotification({
            type: 'error',
            message: 'Error while changing bid: ' + v.errors[0].message,
          })
        }
      })
      .catch((e: any) => {
        showNotification({ type: 'error', message: 'Error: ' + e.message })

        if (process.env.NODE_ENV !== 'development') Sentry.captureException(e)
      })
      .finally(() => {
        setIsChangingRow(false)
        setChangingId(null)
        setReloadCampaignStreams(true)
      })
  }

  const handleResetSettingsClick = (record: { group: string; streamUid: string }) => {
    setIsChangingRow(true)
    setChangingId(record.group)
    const name = groupBy === 'STREAM_ID' ? 'Stream' : 'Substream'
    const fn = groupBy === 'STREAM_ID' ? resetStreamSettings : resetSubstreamSettings
    const input: any = { campaignId: Number(campaignId) }
    if (groupBy === 'STREAM_ID') {
      input.streamUid = record.group
    }
    if (groupBy === 'SUBSTREAM_ID') {
      input.streamUid = record.streamUid
      input.substream = record.group
    }
    fn({ variables: { input } })
      .then((v: any) => {
        if (!v.errors) {
          showNotification({ type: 'success', message: `${name} setting are reset to default` })
          return
        } else {
          showNotification({
            type: 'error',
            message: 'Error while restting settings: ' + v.errors[0].message,
          })
        }
      })
      .catch((e: any) => {
        showNotification({ type: 'error', message: 'Error: ' + e.message })

        if (process.env.NODE_ENV !== 'development') Sentry.captureException(e)
      })
      .finally(() => {
        setIsChangingRow(false)
        setChangingId(null)
        setReloadCampaignStreams(true)
      })
  }

  return (
    <>
      <Form
        form={form}
        id="CampaignForm"
        name="CampaignForm"
        // @ts-ignore
        onSubmit={onFinish}
        initialValues={CampaignStreamsParams}
        style={{ marginBottom: 24 }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Row gutter={{ lg: 12, md: 8 }} style={{ padding: '8px 18px' }}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="groupBy"
              key="groupBy"
              label={t('Campaign.CampaignStreams.Controls.GroupBy')}
              rules={[
                {
                  required: true,
                  message: t('Campaign.CampaignStreams.Controls.GroupByErrorMsg'),
                },
              ]}
            >
              <Select
                onChange={handleGroupByChange}
                placeholder={t('Campaign.CampaignStreams.Controls.GroupByPlaceholder')}
              >
                <Option value="STREAM_ID">{t('Campaign.CampaignStreams.STREAM_ID')}</Option>
                <Option value="SUBSTREAM_ID">{t('Campaign.CampaignStreams.SUBSTREAM_ID')}</Option>
                {/* <Option value="YEAR">{t('Campaign.CampaignStreams.YEAR')}</Option> */}
              </Select>
            </Form.Item>
            {/** @ts-ignore */}
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={
                  <>
                    {t('Campaign.CampaignStreams.Controls.DateRangeLabel')}{' '}
                    <Tag style={{ verticalAlign: 2, marginLeft: 4 }}>UTC{timezone}</Tag>
                  </>
                }
                name="dateRange"
                key="dateRange"
                rules={[
                  {
                    required: false,
                    message: t('Campaign.CampaignStreams.Controls.DateRangeMsg'),
                  },
                ]}
              >
                <RangePicker
                  style={{ width: '100%' }}
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                    'Last 2 Days': [moment().subtract(1, 'days').startOf('day'), moment().endOf('day')],
                    'Last 3 Days': [moment().subtract(2, 'days').startOf('day'), moment().endOf('day')],
                    'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month'),
                      moment().subtract(1, 'month').endOf('month'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <TimezoneSelect title={t('Campaign.CampaignStreams.Controls.Timezone')} name={'timezone'} />
            </Col>
          </Row>
          <Col span={24} style={{ padding: '8px 18px' }}>
            <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Col span={8}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  key="filterByStreamId"
                  label={t('Campaign.CampaignStreams.Controls.StreamFieldLabel')}
                  name="filterByStreamId"
                >
                  <Select
                    mode="multiple"
                    placeholder={t('Campaign.CampaignStreams.Controls.StreamFieldPlaceholder')}
                    onChange={handleChangeStreamId}
                    value={CampaignStreamsParams.filterByStreamId}
                    options={streams}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="start" gutter={4}>
          <Col>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button
                type="primary"
                form="CampaignForm"
                key="submit"
                htmlType="submit"
                onClick={onFinish}
                icon={reloadCampaignStreams ? <LoadingOutlined /> : <FileTextOutlined />}
              >
                {reloadCampaignStreams ? t('Campaign.Loading') : t('Campaign.CampaignStreams.Controls.CreateReport')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button form="CampaignForm" onClick={clearAll} icon={<ClearOutlined />}>
                {t('Campaign.CampaignStreams.Controls.ClearAll')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            {CampaignStreamsCSVData && (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ float: 'right' }}
                key="dowloadCampaignBtn"
              >
                <Button type="dashed" icon={<CloudDownloadOutlined />}>
                  <CSVLink
                    key="csvData"
                    data={CampaignStreamsCSVData}
                    filename={`pushflow_Campaign_${moment().format('YYYY.MM.DD-hh.mm')}.csv`}
                    target="_blank"
                  >
                    {' '}
                    {t('Campaign.CampaignStreams.Controls.DownloadReport')}
                  </CSVLink>
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      {groupBy && (
        <Row style={{ marginBottom: '20px' }} justify="end">
          <Col>
            <PopConfirmButton
              icon={<ClearOutlined />}
              prompt="Are you sure you want to reset all settings?"
              okText="Reset all settings"
              type="dashed"
              buttonText="Reset all bids"
              danger
              onClick={() => {
                resetAllSettings({
                  body: {
                    campaignId: Number(campaignId),
                    settingsType: groupBy === 'STREAM_ID' ? 'STREAM' : 'SUBSTREAM',
                  },
                })
                  .then(() => {
                    showNotification({ type: 'success', message: 'All settings are reset to default' })
                    setReloadCampaignStreams(true)
                  })
                  .catch((e: any) => {
                    console.error('Error during resetting stream/substream settings', e)
                  })
              }}
            ></PopConfirmButton>
          </Col>
        </Row>
      )}
      <Table<{
        title: string
        stream: string
        streamUid: string
        group: string
        streamStatus: string
        substreamStatus: string
        bids: number
        wonBids: number
      }>
        loading={reloadCampaignStreams}
        dataSource={CampaignData}
        rowKey={record => `${record.streamUid}:${record.group}`}
        size="middle"
        scroll={{ x: '1300px' }}
        onChange={handleSettingFilters}
        pagination={pagination}
        rowClassName={record =>
          (((isChangingRow && record.group === changingId) ||
            (groupBy === 'STREAM_ID' ? record.streamStatus : record.substreamStatus) === PAUSED) &&
            'disabled-row') as string
        }
      >
        {CampaignStreamsParams.groupBy === 'STREAM_ID' ? (
          <Column
            title={t(`Campaign.CampaignStreams.Stream`)}
            dataIndex="group"
            key="group"
            filters={CampaignData.map(({ stream }) => ({
              text: stream ? stream.title : '',
              value: stream ? stream.title : '',
            }))}
            fixed="left"
            filterMode="menu"
            filterSearch={true}
            filteredValue={filteredInfo.stream ? filteredInfo.stream : null}
            sortOrder={sortedInfo.columnKey === 'stream' && sortedInfo.order}
            sorter={streamSorter}
            onFilter={(value: any, record: any) => {
              return record.stream ? record.stream.title.includes(value) : false
            }}
            render={(_, record: any) =>
              record.stream ? (
                user?.roles.includes(UserRole.ADMIN) ? (
                  <Link to={`/streams/${record.stream.id}`} target={'_blank'} key={record.stream.id}>
                    <b>{`${record.stream.title}`}</b>
                  </Link>
                ) : (
                  <>{record.stream.title}</>
                )
              ) : null
            }
            width={200}
          />
        ) : (
          <Column
            title={t(`Campaign.CampaignStreams.Substream`)}
            dataIndex="group"
            key="group"
            filters={Array.from(new Set(...[CampaignData.map(e => e.group)])).map(e => ({
              text: e,
              value: e,
            }))}
            width={'150px'}
            fixed={'left'}
            filterMode={'menu'}
            filterSearch={true}
            filteredValue={filteredInfo.group || null}
            sortOrder={sortedInfo.columnKey === 'group' && sortedInfo.order}
            onFilter={(value: any, record: any) => {
              return record.group ? record.group.includes(value) : false
            }}
            render={(text, record) => {
              return `${record.stream?.id}:${text}`
            }}
          />
        )}

        <Column
          title={t('Campaign.CampaignStreams.bids')}
          dataIndex="bids"
          key="bids"
          sorter={(a: { bids: number }, b: { bids: number }) => a.bids - b.bids}
          sortOrder={sortedInfo.columnKey === 'bids' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {' '}
              {t ? t.toLocaleString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.wonBids')}
          dataIndex="wonBids"
          key="wonBids"
          sorter={(a: any, b: any) => a.wonBids - b.wonBids}
          sortOrder={sortedInfo.columnKey === 'wonBids' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {' '}
              {t ? t.toLocaleString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.clicks')}
          dataIndex="clicks"
          key="clicks"
          sorter={(a: any, b: any) => a.clicks - b.clicks}
          sortOrder={sortedInfo.columnKey === 'clicks' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {' '}
              {t ? t.toLocaleString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.winRate')}
          dataIndex="winRate"
          key="winRate"
          sorter={(a: any, b: any) => a.winRate - b.winRate}
          sortOrder={sortedInfo.columnKey === 'winRate' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={(_, r) => (
            <Tooltip placement="top" title={(r.winRate * 100).toFixed(2) + '%'}>
              {' '}
              {(r.winRate * 100).toFixed(2) + '%'}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.cpc')}
          dataIndex="cpc"
          key="cpc"
          sorter={(a: any, b: any) => a.cpc - b.cpc}
          sortOrder={sortedInfo.columnKey === 'cpc' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {' '}
              {t ? Math.round(t * 1000) / 1000 : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.cost')}
          dataIndex="cost"
          key="cost"
          sorter={(a: any, b: any) => a.cost - b.cost}
          sortOrder={sortedInfo.columnKey === 'cost' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {' '}
              {t ? t.toString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title="CR"
          dataIndex="conversionRate"
          key="conversionRate"
          sorter={(a: any, b: any) => a.conversionRate - b.conversionRate}
          sortOrder={sortedInfo.columnKey === 'conversionRate' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={(_, r) => (
            <Tooltip placement="top" title={(r.conversionRate * 100).toFixed(2) + '%'}>
              {' '}
              {(r.conversionRate * 100).toFixed(2) + '%'}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.conv1')}
          dataIndex="conv1"
          key="conv1"
          sorter={(a: any, b: any) => a.conv1 - b.conv1}
          sortOrder={sortedInfo.columnKey === 'conv1' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {t ? t.toString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.conv2')}
          dataIndex="conv2"
          key="conv2"
          sorter={(a: any, b: any) => a.conv2 - b.conv2}
          sortOrder={sortedInfo.columnKey === 'conv2' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {t ? t.toString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.conv3')}
          dataIndex="conv3"
          key="conv3"
          sorter={(a: any, b: any) => a.conv3 - b.conv3}
          sortOrder={sortedInfo.columnKey === 'conv3' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {t ? t.toString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          title={t('Campaign.CampaignStreams.payout')}
          dataIndex="payout"
          key="payout"
          sorter={(a: any, b: any) => a.payout - b.payout}
          sortOrder={sortedInfo.columnKey === 'payout' && sortedInfo.order}
          width="100px"
          ellipsis={{ showTitle: false }}
          render={t => (
            <Tooltip placement="top" title={t ? t.toString() : t}>
              {t ? t.toString() : t}{' '}
            </Tooltip>
          )}
        />
        <Column
          width="120px"
          title={t(`Campaign.CampaignStreams.Toggle${t(`Campaign.CampaignStreams.${CampaignStreamsParams.groupBy}`)}`)}
          render={(t, record: any) => {
            const currentStatus = groupBy === 'STREAM_ID' ? record.streamStatus : record.substreamStatus
            return (
              <Switch
                checked={currentStatus === WORKING}
                onChange={checked => {
                  const status = checked === true ? WORKING : PAUSED
                  handleChangeStatus(record, status)
                }}
              />
            )
          }}
        />
        <Column
          width="150px"
          title={t(`Campaign.CampaignStreams.${t(`Campaign.CampaignStreams.${CampaignStreamsParams.groupBy}`)}Bid`)}
          render={(text, record: any) => {
            const currentBidAmt = groupBy === 'STREAM_ID' ? record.streamBid : record.substreamBid
            let color = 'black'
            if (currentBidAmt > campaignPrice) {
              color = 'green'
            } else if (currentBidAmt < campaignPrice) {
              color = 'red'
            }
            return (
              <Tooltip
                title={`Campaign price is: ${campaignPrice}`}
                overlayStyle={{ fontSize: '10px' }}
                placement="rightBottom"
              >
                <InputNumber
                  size="small"
                  placeholder={t(
                    `Campaign.CampaignStreams.${t(`Campaign.CampaignStreams.${CampaignStreamsParams.groupBy}`)}Bid`
                  )}
                  style={{
                    color,
                  }}
                  value={currentBidAmt}
                  onBlur={e => handleChangeBidFocusLeave(record, parseFloat(e.target.value.replace(',', '.')))}
                  min={0}
                />
              </Tooltip>
            )
          }}
        />
        <Column
          width="150px"
          title={'Reset settings'}
          render={(t, record: any) => {
            return (
              <Tooltip title="Reset price to campaign price and status to working">
                <Button
                  onClick={() => {
                    handleResetSettingsClick(record)
                  }}
                >
                  Reset settings
                </Button>
              </Tooltip>
            )
          }}
        />
        <Column
          width="150px"
          title="Rules applied"
          render={(text, record: any) => {
            return (
              <Space size={[0, 6]} wrap>
                {record.bidChangedByRule ? (
                  <Tooltip title={moment(record.bidChangedByRule).format('YYYY-MM-DD HH:mm')}>
                    <Tag color="blue">Bid changed by rule</Tag>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {record.statusChangedByRule ? (
                  <Tooltip title={moment(record.statusChangedByRule).format('YYYY-MM-DD HH:mm')}>
                    <Tag color="red">Stopped by rule</Tag>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Space>
            )
          }}
        />
      </Table>
    </>
  )
}
export default CampaignsStreamsCampaign
