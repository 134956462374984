import React, { FC, useEffect } from 'react'
import './Logout.sass'
import Cookies from 'js-cookie'

const Logout: FC<{ clearToken: () => void }> = ({ clearToken }) => {
  useEffect(() => {
    clearToken()
    localStorage.removeItem('copiedCreative')
    Cookies.remove('uid')
    window.location.href = '/app/login'
  }, [])
  return <></>
}

export default Logout
