import React, { FC } from 'react'
import { Form, Select } from 'antd'
import './MultiSelect.sass'

const MultiSelect: FC<{
  mode?: 'multiple' | 'tags'
  title: string
  decoratorTitle: string
  children?: React.ReactNode
  type?: 'array' | 'string'
  extra?: React.ReactNode
  required?: boolean
  filterFn?: (input: string, option: any) => boolean
  options?: any[]
  tooltip?: string
  disabled?: boolean
  onChange?: (value: string[]) => void
}> = ({
  mode = 'multiple',
  title,
  decoratorTitle,
  children,
  type,
  extra,
  required,
  filterFn,
  options,
  tooltip,
  disabled,
  onChange,
}) => {
  return (
    <Form.Item
      label={title}
      name={decoratorTitle}
      extra={extra}
      rules={[
        { message: 'Please input ' + title, type },
        ...(required ? [{ required: true, message: 'Please input ' + title }] : []),
      ]}
      tooltip={tooltip || undefined}
    >
      <Select
        disabled={disabled}
        showSearch
        mode={mode}
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        filterOption={(input, option) => {
          if (filterFn) {
            return filterFn(input, option)
          }
          return (option?.title ?? option.value ?? option.key).toLowerCase().includes(input.toLowerCase())
        }}
        options={options}
        onChange={value => {
          if (onChange) {
            onChange(value)
          }
        }}
      >
        {children}
      </Select>
    </Form.Item>
  )
}

export default MultiSelect
