import { ArrowDownOutlined, ArrowUpOutlined, PlusCircleFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, InputNumber, Radio, Row, Select, Tag, TimePicker, Typography } from 'antd'
import React, { FC, useState } from 'react'
import { CampaignRuleAction } from './CampaignRuleAction'
import { useCreateCampaignRuleMutation, useUpdateCampaignRuleMutation } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import showNotification from '../../../../helpers/showNotification'
import { useCampaignStreamReportAutocompleteQuery } from '@pushflow/server/src/graphql/new/campaigns/campaignStreamReportAutocomplete/operations'
import { useGetBlackWhiteListsQuery } from '@pushflow/server/src/graphql/new/blackWhiteLists/getLists/operations'
import moment from 'moment'
import {
  CampaignRuleData,
  CampaignRuleWithData,
  Rule,
  RuleSet,
  RulesStopCampaignAction,
} from '../../../../types/campaign-rule'
import { useAppState } from '../../../../context/AppContext'

export const CONDITION_TARGET = {
  Clicks: 'clicks' as const,
  eCPM: 'ecpm' as const,
  Conversion: 'conversion' as const,
  ROI: 'roi' as const,
  Cost: 'cost' as const,
}

const defaultRule: Rule = {
  conditionTarget: CONDITION_TARGET.Clicks,
  operator: '<',
  value: 1,
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const RuleForm: FC<{ rule: Rule; onRuleChange: (r: Rule) => void }> = ({ rule, onRuleChange }) => {
  const [form] = Form.useForm()

  const initialValues = rule || {
    conditionTarget: CONDITION_TARGET.Clicks,
    operator: '<',
    value: 1,
  }

  const submit = (values: Rule) => {
    onRuleChange(values)
  }

  return (
    <div>
      <Form form={form} initialValues={initialValues} onFinish={submit}>
        <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          <Col span={8}>
            <Form.Item name="conditionTarget">
              <Select
                options={Object.entries(CONDITION_TARGET).map(([label, value]) => {
                  return { label, value }
                })}
                onChange={value => {
                  onRuleChange({ ...rule, conditionTarget: value })
                }}
              />
            </Form.Item>
          </Col>
          <Form.Item name="operator">
            <Select
              options={[
                { label: '<', value: '<' },
                { label: '>', value: '>' },
              ]}
              onChange={value => {
                onRuleChange({ ...rule, operator: value })
              }}
            />
          </Form.Item>

          <Form.Item name="value">
            <Input
              onChange={e => {
                onRuleChange({ ...rule, value: +e.target.value })
              }}
            />
          </Form.Item>
        </Row>
      </Form>
    </div>
  )
}

const getRuleOperator = (rule: RuleSet) => {
  if ('and' in rule) {
    return 'and'
  }
  if ('or' in rule) {
    return 'or'
  }

  throw new Error('Invalid rule op')
}

const defaultRuleSet = (op: 'or' | 'and' = 'and'): RuleSet => {
  return { [op]: [defaultRule] } as RuleSet
}

const getRules = (rules: RuleSet): (Rule | RuleSet)[] => {
  if ('and' in rules) {
    return [...rules.and]
  }
  if ('or' in rules) {
    return [...rules.or]
  }
  throw new Error('Invalid rule')
}

const ruleSet = (op: 'or' | 'and', rules: (Rule | RuleSet)[]): RuleSet => {
  return { [op]: rules } as RuleSet
}

// type T = ['and' | 'or', Array<(Rule | RuleSet)>]

export const CampaignRuleSetForm: FC<{
  rule: RuleSet
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onRuleSetChange: (r: RuleSet) => void
}> = ({ rule, onRuleSetChange }) => {
  const [rules, setRules] = useState<RuleSet>(rule || { and: [defaultRule] })

  return (
    <div>
      <h5>IF</h5>
      <div>
        {Object.entries(rules).map(([key, value]) => {
          return value.map((rule, ruleId) => {
            return (
              <div key={`${key}_${ruleId}`}>
                {('and' in rule || 'or' in rule) && (
                  <Row style={{ marginBottom: 20 }}>
                    <Col span={2}></Col>
                    <Col span={18}>
                      <CampaignRuleSetForm
                        rule={rule}
                        onRuleSetChange={newRule => {
                          const op = getRuleOperator(rules)
                          const newRules = getRules(rules)
                          newRules[ruleId] = newRule
                          const newValue = ruleSet(op, newRules)
                          setRules(newValue)
                          onRuleSetChange && onRuleSetChange(newValue)
                        }}
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        onClick={() => {
                          const newRules = getRules(rules)
                          newRules.splice(ruleId, 1)
                          const operator = getRuleOperator(rules)
                          const newValue = ruleSet(operator, newRules)
                          setRules(newValue)
                          onRuleSetChange && onRuleSetChange(newValue)
                        }}
                      >
                        Delete rule set
                      </Button>
                    </Col>
                  </Row>
                )}
                {'conditionTarget' in rule && (
                  <Row>
                    <Col span={18}>
                      <RuleForm
                        rule={rule}
                        onRuleChange={newRule => {
                          const op = getRuleOperator(rules)
                          const newRules = getRules(rules)
                          newRules[ruleId] = newRule
                          const newValue = ruleSet(op, newRules)
                          setRules(newValue)
                          onRuleSetChange && onRuleSetChange(newValue)
                        }}
                      />
                    </Col>
                    <Col span={2}></Col>
                    <Col span={2}>
                      <Button
                        onClick={() => {
                          const newRules = getRules(rules)
                          newRules.splice(ruleId, 1)
                          const operator = getRuleOperator(rules)
                          const newValue = ruleSet(operator, newRules)
                          setRules(newValue)
                          onRuleSetChange && onRuleSetChange(newValue)
                        }}
                      >
                        Delete rule
                      </Button>
                    </Col>
                  </Row>
                )}

                {value.length > 1 && ruleId !== value.length - 1 && (
                  <Select
                    value={key as 'and' | 'or'}
                    onChange={(newOperator: 'and' | 'or') => {
                      const newRules = getRules(rules)
                      const newValue = ruleSet(newOperator, newRules)
                      setRules(newValue)
                      onRuleSetChange && onRuleSetChange(newValue)
                    }}
                    options={[
                      {
                        label: 'AND',
                        value: 'and',
                      },
                      {
                        label: 'OR',
                        value: 'or',
                      },
                    ]}
                  />
                )}
              </div>
            )
          })
        })}
        <Button
          icon={<PlusCircleFilled />}
          onClick={() => {
            const newRules = getRules(rules)
            newRules.push(defaultRule)
            const operator = getRuleOperator(rules)
            const newValue = ruleSet(operator, newRules)
            setRules(newValue)
            onRuleSetChange && onRuleSetChange(newValue)
          }}
        >
          {getRuleOperator(rules).toUpperCase()}
        </Button>

        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            const newRules = getRules(rules)
            const operator = getRuleOperator(rules)
            if (newRules.length === 1) {
              newRules.push(defaultRule)
              const newValue = ruleSet(operator === 'and' ? 'or' : 'and', newRules)
              setRules(newValue)
              onRuleSetChange && onRuleSetChange(newValue)
              return
            }
            newRules.push({ ...defaultRuleSet(operator) })
            const newValue = ruleSet(operator, newRules)
            setRules(newValue)
            onRuleSetChange && onRuleSetChange(newValue)
          }}
        >
          {getRuleOperator(rules).toUpperCase() === 'AND' ? 'OR' : 'AND'}
        </Button>
      </div>
    </div>
  )
}

const defaultRuleAction = (campaignId: string | number): RulesStopCampaignAction => ({
  action: 'stop',
  rulesTarget: 'campaign',
  targetId: `${campaignId}`,
})

const ConditionOverview: FC<{ rules: RuleSet; isTop: boolean }> = ({ rules, isTop }) => {
  if (!rules) {
    return <>No data about rule</>
  }
  const op = getRuleOperator(rules)
  const rulesList = getRules(rules)
  return (
    <Typography.Text>
      {isTop ? <></> : <b>{'('}&nbsp;</b>}
      {rulesList.map((rule, id) => {
        if ('and' in rule || 'or' in rule) {
          return (
            <div
              style={{
                display: 'inline',
              }}
              key={id}
            >
              <ConditionOverview rules={rule} isTop={false} />
              {id !== rulesList.length - 1 ? <b>&nbsp;{op}&nbsp;</b> : <></>}
            </div>
          )
        }
        return (
          <div
            style={{
              display: 'inline',
            }}
            key={id}
          >
            {`${rule.conditionTarget} ${rule.operator} ${rule.value}`}
            {id !== rulesList.length - 1 ? <b>&nbsp;{op}&nbsp;</b> : <></>}
          </div>
        )
      })}
      {isTop ? <></> : <b>&nbsp;{')'}</b>}
    </Typography.Text>
  )
}

export const CampaignRule: FC<{
  campaign: { id: number; userId: number; price: number; hasTargetCpa: boolean }
  rule?: CampaignRuleWithData
}> = ({ campaign, rule }) => {
  const { apiClient } = useAppState()

  const navigate = useNavigate()
  const [ruleTitle, setRuleTitle] = useState((rule && rule.title) || null)
  const [campaignRuleData, setCampaignRuleData] = useState<CampaignRuleData>(
    rule && rule.data
      ? rule.data
      : {
          rules: defaultRuleSet('and'),
          ruleAction: defaultRuleAction(campaign.id),
          timeframe: 7,
        }
  )

  const [timeframeType, setTimeframeType] = useState(rule && rule.data.timeframe === 'yesterday' ? 'yesterday' : 'last')

  const [frequencyType, setFrequencyType] = useState(rule && rule.frequencyType ? rule.frequencyType : 'every')
  const [frequency, setFrequency] = useState(rule && rule.frequency ? rule.frequency : 1)

  const createRule = useCreateCampaignRuleMutation(campaign.id)
  const updateRule = useUpdateCampaignRuleMutation({
    campaignId: campaign.id,
    rule: rule || null,
  })

  const { data: campaigns } = apiClient.campaign.getCampaignListPaginated.useQuery(['campaigns'], {
    query: {
      pagination: { pageNumber: 0, pageSize: 10000 },
    },
  })

  const streams = useCampaignStreamReportAutocompleteQuery()
  const blwls = useGetBlackWhiteListsQuery()

  const streamLists = blwls.data?.getBlackWhiteLists?.blackWhiteLists.filter(
    (l: { listType: 'STREAM' | 'SUBSTREAM' }) => l.listType === 'STREAM'
  )
  const substreamLists = blwls.data?.getBlackWhiteLists?.blackWhiteLists.filter(
    (l: { listType: 'STREAM' | 'SUBSTREAM' }) => l.listType === 'SUBSTREAM'
  )

  return (
    <Col>
      <Row style={{ marginBottom: '10px' }}>
        {rule && rule.lastCheckedAt ? (
          <Row>
            <Tag color={'blue'}>Last checked at: {moment(rule.lastCheckedAt).format('YYYY-MM-DD HH:mm')}</Tag>
          </Row>
        ) : (
          <></>
        )}
        {rule && rule.lastTriggeredAt ? (
          <Row>
            <Tag color={'red'}>Last triggered at: {moment(rule.lastTriggeredAt).format('YYYY-MM-DD HH:mm')}</Tag>
          </Row>
        ) : (
          <></>
        )}
      </Row>
      <Form.Item label="Title" tooltip="Rule title">
        <Input
          value={ruleTitle || ''}
          onChange={e => {
            setRuleTitle(e.target.value)
          }}
        />
      </Form.Item>

      <Divider />
      <Typography.Title level={5}>Condition</Typography.Title>
      <ConditionOverview rules={campaignRuleData.rules} isTop={true} />
      <CampaignRuleSetForm
        rule={campaignRuleData.rules}
        onRuleSetChange={newRuleSet => {
          setCampaignRuleData({
            ...campaignRuleData,
            rules: newRuleSet,
          })
        }}
      />

      <Divider />

      <Typography.Title level={5}>Action</Typography.Title>
      <CampaignRuleAction
        campaignId={campaign.id}
        campaignPrice={campaign.price}
        action={campaignRuleData.ruleAction}
        availableStreams={streams.data?.campaignStreamReportAutoComplete?.streams ?? []}
        availableCampaigns={campaigns?.body.items ?? []}
        availableStreamBlacklists={streamLists ?? []}
        availableSubstreamBlacklists={substreamLists ?? []}
        // TODO: any
        onRuleActionChange={(newRuleAction: any) => {
          setCampaignRuleData({
            ...campaignRuleData,
            ruleAction: newRuleAction,
          })
        }}
        campaignHasTargetCpa={campaign.hasTargetCpa}
      />

      <Divider />

      <Form.Item
        label="Data analysis period"
        tooltip="Timeframe in days(min 1 day, max 30 days)"
        extra={
          <Radio.Group
            value={timeframeType}
            onChange={e => {
              setTimeframeType(e.target.value)
              setCampaignRuleData({
                ...campaignRuleData,
                timeframe: e.target.value === 'yesterday' ? 'yesterday' : 7,
              })
            }}
          >
            <Radio.Button value="last">Last</Radio.Button>
            <Radio.Button value="yesterday">Yesterday</Radio.Button>
          </Radio.Group>
        }
      >
        <Row gutter={2}>
          {timeframeType === 'last' && (
            <InputNumber
              value={campaignRuleData.timeframe}
              min={1}
              max={30}
              onChange={v => {
                setCampaignRuleData({
                  ...campaignRuleData,
                  timeframe: v as number | 'yesterday',
                })
              }}
            />
          )}
          {timeframeType === 'last' && <Typography.Text>Days</Typography.Text>}
        </Row>
      </Form.Item>

      <Row>
        <Form.Item label="Repeat check" tooltip="How often the rule will be checked">
          <Radio.Group
            onChange={e => {
              setFrequencyType(e.target.value)
            }}
            value={frequencyType}
          >
            <Radio.Button value="at">Every day at</Radio.Button>
            <Radio.Button value="every">Every</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          {frequencyType === 'at' && (
            <TimePicker
              defaultValue={moment('12:00', 'HH')}
              format={'HH'}
              value={moment(frequency, 'HH')}
              onChange={(time, timeString) => {
                setFrequency(timeString)
              }}
            />
          )}
          {frequencyType === 'every' && (
            <Input
              onChange={e => {
                if (e.target.value === '') {
                  setFrequency('')
                  return
                }
                const value = isNaN(parseInt(e.target.value)) || +e.target.value < 1 ? 1 : parseInt(e.target.value)
                setFrequency(value)
              }}
              value={frequency}
            />
          )}
        </Form.Item>
        <>&nbsp;Hours</>
      </Row>

      <Button
        onClick={() => {
          if (rule && rule.id) {
            updateRule.mutate(
              {
                rule: {
                  id: rule.id,
                  title: ruleTitle,
                  data: campaignRuleData,
                  frequency: frequency ? `${frequency}` : '1',
                  frequencyType,
                },
                campaignId: campaign.id,
              },
              {
                onSuccess: () => {
                  showNotification({
                    description: 'Success',
                    message: 'Rule updated',
                    type: 'success',
                  })
                  navigate(`/rules/${campaign.id}`)
                },
                onError: () => {
                  showNotification({
                    description: 'Error',
                    message: 'Rule update failed',
                    type: 'error',
                  })
                },
              }
            )
            return
          }
          createRule.mutate(
            {
              rule: {
                title: ruleTitle,
                data: campaignRuleData,
                frequency: `${frequency}`,
                frequencyType,
              },
              campaignId: campaign.id,
            },
            {
              onSuccess: () => {
                showNotification({
                  description: 'Success',
                  message: 'Rule created',
                  type: 'success',
                })

                navigate(`/rules/${campaign.id}`)
              },
              onError: () => {
                showNotification({
                  description: 'Error',
                  message: 'Rule creation failed',
                  type: 'error',
                })
              },
            }
          )
        }}
        type="primary"
      >
        {rule && rule.id ? 'Update rule' : 'Save Rule'}
      </Button>
    </Col>
  )
}

const ACTIONS = {
  stop: 'Stop',
  increase_bid: (
    <>
      <ArrowUpOutlined />
      &nbsp;Increase bid on
    </>
  ),
  decrease_bid: (
    <>
      <ArrowDownOutlined />
      &nbsp;Decrease bid on
    </>
  ),
  add_to_list: 'Add to list',
}

const ACTION_COLORS = {
  stop: 'red',
  increase_bid: 'coral',
  decrease_bid: 'orange',
  add_to_list: 'blue',
}

export const RuleActionView: FC<{ rule: CampaignRuleData; currentPrice: number }> = ({ rule, currentPrice }) => {
  return (
    <Col>
      <Row>
        <Col>
          <Tag color={ACTION_COLORS[rule.ruleAction.action]}>{ACTIONS[rule.ruleAction.action]}</Tag>
        </Col>
        {rule.ruleAction.action === 'add_to_list' ? (
          <Col>
            &nbsp;<Tag>{rule.ruleAction.listId}</Tag>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col>{rule.ruleAction.rulesTarget}</Col>&nbsp;
        <Col>
          <Tag>{rule.ruleAction.targetId}</Tag>
        </Col>
      </Row>
      {rule.ruleAction.action === 'increase_bid' || rule.ruleAction.action === 'decrease_bid' ? (
        <Row>
          &nbsp;for&nbsp;{rule.ruleAction.value}$ until&nbsp;{rule.ruleAction.limit}$ Current&nbsp;price:&nbsp;
          {currentPrice}$
        </Row>
      ) : null}
    </Col>
  )
}

const OpView: FC<{ operation: 'and' | 'or' }> = ({ operation }) => {
  return <b>{operation.toUpperCase()}</b>
}

export const RuleConditionView: FC<{ rules: any; isTop?: boolean; rule: CampaignRuleWithData }> = ({
  rules,
  isTop = true,
  rule,
}) => {
  const [op] = Object.keys(rules) as Array<'and' | 'or'>

  return (
    <Col>
      {isTop ? <Row>When</Row> : <></>}
      {rules[op].map((r: any, i: number) => {
        if ('and' in r || 'or' in r) {
          return (
            <Col key={i}>
              <Row gutter={24}>
                <RuleConditionView rules={r} isTop={false} rule={rule} />
              </Row>
              <Row>
                {rules[op].length > 1 && rules[op].length - 1 !== i ? (
                  <Row>
                    <OpView operation={op} />
                  </Row>
                ) : null}
              </Row>
            </Col>
          )
        }
        return (
          <Col key={i}>
            <Row gutter={24}>
              <Col>
                <Tag>{r.conditionTarget}</Tag>
              </Col>
              <Col>{r.operator}</Col>
              <Col>{r.value}</Col>
            </Row>
            <Row>
              {rules[op].length > 1 && rules[op].length - 1 !== i ? (
                <Row>
                  <OpView operation={op} />
                </Row>
              ) : null}
            </Row>
          </Col>
        )
      })}
      {isTop ? (
        <>
          &nbsp;for&nbsp;
          {rule.data.timeframe === 'yesterday' ? 'yesterday' : `last ${rule.data.timeframe} days`}
          <br />
          <Tag>{`Repeat check every ${rule.frequencyType === 'at' ? 'day at ' : ' '} ${rule.frequency} hours`}</Tag>
        </>
      ) : (
        <></>
      )}
    </Col>
  )
}
