import React, { FC, useEffect, useRef, useState } from 'react'

import { Row, Col } from 'antd'

// @ts-ignore
import AvatarEditor from 'react-avatar-editor'

import './ImageCropper.sass'

import { PUSH_CREATIVE_BODY_IMG_TYPE, PUSH_CREATIVE_PREVIEW_IMG_TYPE } from '../../common/types/creativeImageTypes'

export const useImageCropperController = () => {
  const [image, setImage] = useState<{
    ref: React.RefObject<any> | null
    src: string
    name: string
    type: string
  }>({
    ref: null,
    src: '',
    name: '',
    type: '',
  })
  const getImageUpload = async () => {
    if (!image.ref?.current) return null
    const canvas = image.ref.current.getImage().toDataURL()
    const blob = await fetch(canvas).then(res => res.blob())
    return new File([blob], image.name, { type: image.type })
  }
  return {
    image,
    setImage,
    getImageUpload,
    clearImage: () => setImage({ ref: null, src: '', name: '', type: '' }),
  }
}

const ImageCropper: FC<{
  title: string
  description: string
  type: typeof PUSH_CREATIVE_PREVIEW_IMG_TYPE | typeof PUSH_CREATIVE_BODY_IMG_TYPE
  width: number
  height: number
  imageCropperController: any
  creative: any
}> = ({ title, description, type, width, height, imageCropperController, creative }) => {
  const imgRef = useRef(null)

  const imagePath = type === PUSH_CREATIVE_PREVIEW_IMG_TYPE ? creative.previewImgPath : creative.bodyImgPath
  const imageName = type === PUSH_CREATIVE_PREVIEW_IMG_TYPE ? creative.previewImgName : creative.bodyImgName
  const imageUrl = type === PUSH_CREATIVE_PREVIEW_IMG_TYPE ? creative.previewImgUrl : creative.bodyImgUrl

  useEffect(() => {
    if (imgRef.current && !imageCropperController.image.ref) {
      imageCropperController.setImage((image: any) => ({
        ...image,
        ref: imgRef,
      }))
    }
  }, [imgRef, imageCropperController.image])

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        imageCropperController.setImage({
          src: reader.result,
          name: file.name,
          type: file.type,
        })
      })
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="ImageCropper">
      <Row>
        <Col span={14}>
          <h5 className="ImageCropper__title">{title}</h5>
          <p className="ImageCropper__description">{description}</p>
        </Col>
        <Col span={10}>
          <input
            type="file"
            accept="image/jpg, image/jpeg, image/png"
            onChange={onSelectFile}
            className="ImageCropper__input"
          />
        </Col>
      </Row>
      {creative.id && imagePath && imageUrl && !imageCropperController.image.src && (
        <Row style={{ marginBottom: 12 }}>
          <Col>
            <div>{imageName}</div>
            <div style={{ marginBottom: 10 }}>
              <img src={imageUrl} alt="" width={width} height={height} />
            </div>
            {/* <Button disabled={!creative.id || !imagePath || isLoading} type="dashed" onClick={() => onDeleteImage()}>
              {t('CreativeForm.Delete current image')}
            </Button> */}
          </Col>
        </Row>
      )}
      <Row>
        {imageCropperController.image.src && (
          <Col>
            <div>{imageCropperController.image.name}</div>
            <AvatarEditor
              ref={imgRef}
              image={imageCropperController.image.src}
              width={width}
              height={height}
              rotate={0}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default ImageCropper
