import { z } from 'zod';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const report = c.router({
  getAdvertiserSummaryReport: {
    method: 'GET',
    path: '/report.getAdvertiserSummaryReport',
    responses: {
      200: z.object({
        costToday: z.number(),
        costYesterday: z.number(),
        cost7days: z.number(),
        costThisMonth: z.number(),
        clicksToday: z.number(),
        clicksYesterday: z.number(),
        clicks7days: z.number(),
        clicksThisMonth: z.number(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getPublisherSummaryReport: {
    method: 'GET',
    path: '/report.getPublisherSummaryReport',
    responses: {
      200: z.object({
        costToday: z.number(),
        costYesterday: z.number(),
        cost7days: z.number(),
        costThisMonth: z.number(),
        clicksToday: z.number(),
        clicksYesterday: z.number(),
        clicks7days: z.number(),
        clicksThisMonth: z.number(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getStreamReportFilterValues: {
    method: 'GET',
    path: '/report.getStreamReportFilterValues',
    responses: {
      200: z.object({
        streams: z
          .object({
            id: z.number(),
            title: z.string(),
            uid: z.string(),
            userId: z.number(),
          })
          .array(),
        users: z
          .object({
            id: z.number(),
            email: z.string(),
          })
          .array(),
        targets: z
          .object({
            type: z.string(),
            values: z.string().array(),
          })
          .array(),
      }),
    },
  },

  getAdvertiserReportFilterValues: {
    method: 'GET',
    path: '/report.getAdvertiserReportFilterValues',
    responses: {
      200: z.object({
        users: z
          .object({
            id: z.number(),
            email: z.string(),
          })
          .array(),
      }),
    },
  },

  getCampaignReportFilterValues: {
    method: 'GET',
    path: '/report.getCampaignReportFilterValues',
    responses: {
      200: z.object({
        users: z
          .object({
            id: z.number(),
            email: z.string(),
          })
          .array(),
        targets: z
          .object({
            type: z.string(),
            values: z.string().array(),
          })
          .array(),
        campaigns: z
          .object({
            id: z.number(),
            title: z.string(),
            userId: z.number(),
          })
          .array(),
        creatives: z
          .object({
            id: z.number(),
            title: z.string().nullable(),
            userId: z.number(),
            campaignId: z.number(),
          })
          .array(),
        campaignTags: z.string().array(),
        creativeTags: z.string().array(),
      }),
    },
  },
});
