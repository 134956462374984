import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { paginationRequest, paginationResponse } from './common/pagination';
import { ErrorCode } from './common/error.enum';

const c = initContract();

export const userAuditory = c.router({
  getUserAuditoryListPaginated: {
    method: 'GET',
    path: '/userAuditory/getUserAuditoryListPaginated',
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            title: z.string(),
            userId: z.number(),
          })
          .array(),
        pagination: paginationResponse,
        filters: z.object({
          titles: z.string().array(),
          userIds: z.number().array(),
        }),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
    query: z.object({
      filter: z
        .object({
          title: z.string().optional(),
          userIds: z.number().array().optional(),
          ids: z.coerce.number().array().optional(),
        })
        .optional(),
      pagination: paginationRequest,
    }),
  },

  getUserAuditory: {
    method: 'GET',
    path: '/userAuditory/getUserAuditory',
    query: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        title: z.string(),
        usersCount: z.number(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.USER_AUDITORY_NOT_FOUND]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  createUserAuditory: {
    method: 'POST',
    path: '/userAuditory/createUserAuditory',
    body: z.object({
      title: z.string(),
    }),
    responses: {
      201: z.object({
        id: z.number(),
        title: z.string(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.USER_AUDITORY_ALREADY_EXISTS]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  updateUserAuditory: {
    method: 'POST',
    path: '/userAuditory/updateUserAuditory',
    body: z.object({
      id: z.number(),
      title: z.string(),
    }),
    responses: {
      201: z.object({
        id: z.number(),
        title: z.string(),
      }),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.USER_AUDITORY_NOT_FOUND,
          ErrorCode.USER_AUDITORY_ALREADY_EXISTS,
        ]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  deleteUserAuditory: {
    method: 'POST',
    path: '/userAuditory/deleteUserAuditory',
    body: z.object({
      id: z.number(),
    }),
    responses: {
      201: z.object({
        ok: z.boolean(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.USER_AUDITORY_NOT_FOUND]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  checkIsUserInAuditory: {
    method: 'POST',
    path: '/userAuditory/checkIsUserInAuditory',
    body: z.object({
      userId: z.string(),
      stream: z.string(),
      auditoryId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        isUserInAuditory: z.boolean(),
      }),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.USER_AUDITORY_NOT_FOUND,
          ErrorCode.STREAM_NOT_FOUND,
        ]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  addUserToAuditory: {
    method: 'POST',
    path: '/userAuditory/addUserToAuditory',
    body: z.object({
      userId: z.string(),
      stream: z.string(),
      auditoryId: z.coerce.number(),
    }),
    responses: {
      201: z.object({
        ok: z.boolean(),
      }),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.USER_AUDITORY_NOT_FOUND,
          ErrorCode.USER_ALREADY_IN_AUDITORY,
          ErrorCode.STREAM_NOT_FOUND,
        ]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  removeUserFromAuditory: {
    method: 'POST',
    path: '/userAuditory/deleteUserFromAuditory',
    body: z.object({
      userId: z.string(),
      stream: z.string(),
      auditoryId: z.coerce.number(),
    }),
    responses: {
      201: z.object({
        ok: z.boolean(),
      }),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.USER_AUDITORY_NOT_FOUND,
          ErrorCode.USER_NOT_IN_AUDITORY,
          ErrorCode.STREAM_NOT_FOUND,
        ]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getCampaignsWithAuditory: {
    method: 'GET',
    path: '/userAuditory/getCampaignsWithAuditory',
    query: z.object({
      auditoryId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            title: z.string(),
          })
          .array(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.USER_AUDITORY_NOT_FOUND]),
        message: z.string().optional(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
