import { gql } from 'graphql-tag'

export const campaignFullFragment = gql`
  fragment campaignFullFragment on Campaign {
    __typename
    id
    title
    url
    status

    campaignType
    bidType

    subscriptionAgeEnabled
    subscriptionAgeFrom
    subscriptionAgeTo
    subscriptionAgeUnitsChoice

    lang
    langInclude
    countryCode
    countryCodeInclude
    region
    regionInclude
    device
    deviceInclude
    os
    osInclude
    osVersion
    osVersionInclude
    browser
    browserInclude
    browserVersion
    browserVersionInclude
    browserVersionOp
    connectionType
    connectionTypeInclude
    isp
    ispInclude
    streams
    streamsInclude
    substreams
    substreamsInclude
    domains
    domainsInclude
    useragents
    useragentsInclude

    maxClicked
    maxShown
    targetDaysAndHours {
      day
      hour
    }
    scheduleTimezone
    updatedAt
    createdAt
    creatives {
      id
      campaignId
    }
    maxDailyBudget
    maxTotalBudget
    userAuditoryConditions {
      auditoryId
      threshold
      conditionType
    }
    blacklists {
      id
      title
    }
    whitelists {
      id
      title
    }
  }
`

export const campaignPreviewFragment = gql`
  fragment campaignPreviewFragment on Campaign {
    __typename
    id
    title
    status
    campaignType
    bidType
    countryCode
    region
    url
    streams
    streamIds
    price
    targetCpa
    user {
      id
    }
    streamsInfo {
      id
      title
    }
    spent
    maxDailyBudget
    enabledNotificationType
    maxTotalBudget
  }
`

export const CAMPAIGN_DATA = gql`
  fragment CampaignData on Campaign {
    count
    __typename
    id
    title
    url
    status

    userId
    notificationEnabled
    campaignType
    bidType

    lang
    langInclude
    countryCode
    countryCodeInclude
    region
    regionInclude
    deviceOld
    device
    deviceInclude
    osOld
    os
    osInclude
    osVersionOld
    osVersion
    osVersionInclude
    browserOld
    browser
    browserInclude
    browserVersionOld
    browserVersion
    browserVersionInclude
    browserVersionOp
    connectionType
    connectionTypeInclude
    isp
    ispInclude
    streams
    streamsInclude
    substreams
    substreamsInclude
    domains
    domainsInclude
    useragents
    useragentsInclude

    tags

    streamIds

    subscriptionAgeEnabled
    subscriptionAgeFrom
    subscriptionAgeTo
    subscriptionAgeUnitsChoice
    maxClicked
    maxShown
    maxClickedUser
    maxShownUser
    maxClickedUserAmtPeriod
    maxShownUserAmtPeriod
    maxClickedUserPeriod
    maxShownUserPeriod
    price
    targetCpa
    targetDaysAndHours {
      day
      hour
    }
    scheduleTimezone
    updatedAt
    createdAt
    creatives {
      id
      campaignId
    }
    maxDailyBudget
    maxTotalBudget

    auditories {
      id
      title
    }
    auditoriesInclude
    userAuditoryConditions {
      auditoryId
      threshold
      conditionType
    }
    blacklists {
      id
      title
    }
    whitelists {
      id
      title
    }
  }
`
