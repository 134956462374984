export enum ErrorCode {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  // DSP users
  USER_IS_REGISTERED = 'USER_IS_REGISTERED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_ACTIVATED = 'USER_NOT_ACTIVATED',
  VERIFICATION_CODE_INVALID = 'VERIFICATION_CODE_INVALID',
  VERIFICATION_CODE_TOO_MANY_REQUEST = 'VERIFICATION_CODE_TOO_MANY_REQUEST',
  EMAIL_NOT_CONFIRMED = 'EMAIL_NOT_CONFIRMED',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
  // User auditories
  USER_AUDITORY_ALREADY_EXISTS = 'USER_AUDITORY_ALREADY_EXISTS',
  USER_AUDITORY_NOT_FOUND = 'USER_AUDITORY_NOT_FOUND',
  USER_AUDITORY_WRONG_STREAM_FORMAT = 'USER_AUDITORY_WRONG_STREAM_FORMAT',
  USER_ALREADY_IN_AUDITORY = 'USER_ALREADY_IN_AUDITORY',
  USER_NOT_IN_AUDITORY = 'USER_NOT_IN_AUDITORY',
  // Streams
  STREAM_NOT_FOUND = 'STREAM_NOT_FOUND',
  // Campaigns
  CAMPAIGN_NOT_FOUND = 'CAMPAIGN_NOT_FOUND',
  CANT_DELETE_LAST_BUDGET_LIMIT = 'CANT_DELETE_LAST_BUDGET_LIMIT',
  CAMPAIGN_WRONG_DOMAIN = 'CAMPAIGN_WRONG_DOMAIN',
  // Creatives
  CREATIVE_NOT_FOUND = 'CREATIVE_NOT_FOUND',
  CREATIVE_NOT_APPROVED = 'CREATIVE_NOT_APPROVED',
  CREATIVE_EMPTY = 'CREATIVE_EMPTY', // No media files provided
  CREATIVE_VIDEO_AND_IMAGE = 'CREATIVE_VIDEO_AND_IMAGE', // Both video and image provided
  CREATIVE_ONE_IMAGE = 'CREATIVE_ONE_IMAGE', // Only one image provided
  CREATIVE_BODY_REQUIRED = 'CREATIVE_BODY_REQUIRED', // Body is required for this type of creative
  CREATIVE_WORNG_MIMETYPE = 'CREATIVE_WORNG_MIMETYPE', // Wrong mime type
}

export const ErrorCodeToMessage: Record<
  ErrorCode,
  { type: 'error' | 'warn'; title?: string; message: string }
> = {
  [ErrorCode.UNKNOWN_ERROR]: {
    type: 'error',
    title: 'Error!',
    message: 'Unknown error',
  },
  [ErrorCode.USER_IS_REGISTERED]: {
    type: 'warn',
    title: 'Attention!',
    message: 'User is already registered. Please login or recover password',
  },
  [ErrorCode.USER_NOT_FOUND]: {
    type: 'error',
    message: 'User not found',
  },
  [ErrorCode.USER_NOT_ACTIVATED]: {
    type: 'warn',
    title: 'Attention!',
    message: 'Your profile is not activated by the platform administrator',
  },
  [ErrorCode.VERIFICATION_CODE_INVALID]: {
    type: 'error',
    message: 'Verification code is invalid',
  },
  [ErrorCode.VERIFICATION_CODE_TOO_MANY_REQUEST]: {
    type: 'error',
    message: 'Code request can be repeated after 1 minute',
  },
  [ErrorCode.EMAIL_NOT_CONFIRMED]: {
    type: 'error',
    message: 'Email is not confirmed',
  },
  [ErrorCode.PASSWORD_INVALID]: {
    type: 'error',
    message: 'Password is invalid',
  },
  [ErrorCode.USER_AUDITORY_NOT_FOUND]: {
    type: 'error',
    message: 'User auditory not found',
  },
  [ErrorCode.USER_AUDITORY_WRONG_STREAM_FORMAT]: {
    type: 'error',
    message: 'Wrong stream format',
  },
  [ErrorCode.USER_ALREADY_IN_AUDITORY]: {
    type: 'warn',
    message: 'User already in auditory',
  },
  [ErrorCode.USER_NOT_IN_AUDITORY]: {
    type: 'warn',
    message: 'User not in auditory',
  },
  [ErrorCode.USER_AUDITORY_ALREADY_EXISTS]: {
    type: 'error',
    message: 'User auditory with same name already exists',
  },
  [ErrorCode.STREAM_NOT_FOUND]: {
    type: 'error',
    message: 'Stream not found',
  },
  [ErrorCode.CAMPAIGN_NOT_FOUND]: {
    type: 'error',
    message: 'Campaign not found',
  },
  [ErrorCode.CANT_DELETE_LAST_BUDGET_LIMIT]: {
    type: 'error',
    message: `Can't delete last budget limit for campaign. Campaign must have either total budget limit or daily budget limit`,
  },
  [ErrorCode.CAMPAIGN_WRONG_DOMAIN]: {
    type: 'error',
    message: 'Wrong domain',
  },
  [ErrorCode.CREATIVE_NOT_FOUND]: {
    type: 'error',
    message: 'Creative not found',
  },
  [ErrorCode.CREATIVE_NOT_APPROVED]: {
    type: 'error',
    message: 'Creative not approved',
  },
  [ErrorCode.CREATIVE_EMPTY]: {
    type: 'error',
    message: 'No media files provided',
  },
  [ErrorCode.CREATIVE_VIDEO_AND_IMAGE]: {
    type: 'error',
    message: 'Both video and image provided',
  },
  [ErrorCode.CREATIVE_ONE_IMAGE]: {
    type: 'error',
    message: 'Only one image provided',
  },
  [ErrorCode.CREATIVE_BODY_REQUIRED]: {
    type: 'error',
    message: 'Body is required for this type of creative',
  },
  [ErrorCode.CREATIVE_WORNG_MIMETYPE]: {
    type: 'error',
    message: 'Wrong mime type. Supported image formats: .jpeg, .png',
  },
};
